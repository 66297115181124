export const emailApp = {
    userId : process.env.REACT_APP_EMAILJS_USER_ID,
    contact: {
        serviceId  : process.env.REACT_APP_EMAILJS_CONTACT_SERVICE_ID,
        templateId : process.env.REACT_APP_EMAILJS_CONTACT_TEMPLATE_ID
    },
    contactReply : {
        serviceId  : process.env.REACT_APP_EMAILJS_CONTACT_SERVICE_ID,
        templateId : process.env.REACT_APP_EMAILJS_CONTACT_REPLY_TEMPLATE_ID
    }
};