// モジュール
import { getDoc, doc } from 'firebase/firestore';

// データ
import { BlogConverter } from '../../../data/firebase/blog/Blog';
import { BlogData } from '../../../data/common/BlogData';
import { STATUS_CODE } from '../../../data/common/StatusCode';

export const getBlog = async (
        firestore   : any,
        setBlog     : any,
        blogId      : number,
    ) => {
        let inStatus = STATUS_CODE.error.notAcceptable;

        // 参照先指定
        const BLOG_REF = doc(
            firestore,
            BlogData.BLOGS,
            String(blogId),
        ).withConverter(BlogConverter);

        //　ブログデータ取得
        await getDoc(BLOG_REF)
            .then((snapShoto) => {
                let isData = {};                // 取得データ
                const DATA = snapShoto.data();  // データ取得

                // データがある場合
                if(DATA){
                    inStatus = STATUS_CODE.success.ok;
                    isData   = DATA;
                }

                setBlog({
                    status : inStatus,
                    data   : isData,
                });
            })
            .catch((err) => {
                setBlog({
                    status : STATUS_CODE.error.badRequest,
                });
            });
}