// モジュール
import React, { useState, useEffect } from 'react';
import imageCompression from 'browser-image-compression';
import { setNoPhoto } from '../../method/common/setNoPhoto';
import { formGetValueObj } from '../../method/form/formGetValueObj';
import { formGetValueArr } from '../../method/form/formGetValueArr';

function FormImages({props} : any){
    const {
        LABEL,
        TYPE,
        IMAGE_FLG,
        VALUE,
        setValues,
        VALUE_TYPE,
        VALUES = [],
        CURRENT = 0,
        disabredCheck,
    } = props;
     // 初期値有無判定とセット
     const DEFAULT_VALUE = VALUE !== undefined ? VALUE : '';

    const [errMessage, setErrMessage] = useState('');   // エラーメッセージ用
    const [imageUrl, setImageUrl] = useState('');       // 画像URL取得用
    const [imageFlg, setImageFlg] = useState(false);    // 画像フラグ
    const compressOptions = {
        maxSizeMB : 3,
        maxWidthOrHeight: 1024
    }

    async function getValue(files : any) {
        const file = files[0];

        // 圧縮処理
        try{
            // 画像圧縮
            const compressFile = await imageCompression(file, compressOptions);
            // URL取得     
            const url = await imageCompression.getDataUrlFromFile(compressFile);

            // URLを渡して画像表示
            setImageUrl(url);
            // 画像フラグあり
            setImageFlg(true);
            // 値を渡す
            if(CURRENT === -1){
                formGetValueObj(VALUES, setValues, VALUE_TYPE, disabredCheck, true, compressFile);
            }else{
                formGetValueArr(VALUES, setValues, VALUE_TYPE, CURRENT, true, compressFile);
            }
            // エラ〜メッセージなし
            setErrMessage('');
        }catch(e){
            // エラ〜メッセージあり
            setErrMessage('圧縮に失敗しました。');
        }
    }

    // 画像削除処理
    const clickDelete = () => {
        if(window.confirm('削除してよろしいですか？')){
            // 画像URLを削除                  
            setImageUrl('');
            // 画像フラグ1なし                      
            setImageFlg(false);
            // 値を渡す
            if(CURRENT === -1){
                formGetValueObj(VALUES, setValues, VALUE_TYPE, disabredCheck, false, '');
            }else{
                formGetValueArr(VALUES, setValues, VALUE_TYPE, CURRENT, false, '');
            }
        }
    }

    useEffect(() => {
        setImageUrl(DEFAULT_VALUE);
        setImageFlg(IMAGE_FLG);
        // 初期値があれば値セット
        if(IMAGE_FLG){
            if(CURRENT === -1){
                formGetValueObj(VALUES, setValues, VALUE_TYPE, disabredCheck, IMAGE_FLG, '');
            }else{
                formGetValueArr(VALUES, setValues, VALUE_TYPE, CURRENT, IMAGE_FLG, '');
            }
        }
    }, [DEFAULT_VALUE, IMAGE_FLG, CURRENT, VALUES, setValues, VALUE_TYPE, disabredCheck]);

    return(
        <section className='form_label image'>
            <label>{ LABEL }</label>
            <div className='view_images'>
                <img src={ imageFlg ? imageUrl : setNoPhoto() } alt='投稿画像' />
                <input
                    type={ TYPE }
                    multiple
                    accept='image/jpeg,image/png'
                    onChange={ e => { getValue(e.target.files) } }
                    className='form_image'
                />
                <p
                    className={ `image_delete_btn ${ imageFlg ? '' : 'hidden' }` }
                    onClick={ () => { clickDelete() } }
                >×</p>
            </div>
            <p className='formError'>{ errMessage }</p>
        </section>
    )
}

export default FormImages;
