function formatCheck(
        value         : string,
        type          : string,
        setErrMessage : (message : string) => void
    ){

    let reg : RegExp = /^$/;
    let regMessage : string = '';
    let res = false;

    switch(type){
        case 'email' :
            reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
            regMessage = 'メールアドレス'
            break;
        case 'tel' :
            reg = /^0[0-9]{10}$/;
            regMessage = '電話番号'
            break;
    }

    // 型式チェックを行う場合
    if(regMessage !== ''){
        res = !reg.test(value);
        regMessage += 'の形式が違います。';
    }

    // 型式が一致しない場合
    if(res){
        setErrMessage(regMessage);
    }

    return res;
}

export default formatCheck;