import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../../style/common/inCarousel.css';

function InCarousel({ props } : any){
    const { dataList, containerClass = '', itemClass = '', dotListClass = '' } = props;

    const PC_WIDTH = 1000;                               // PC画面幅指定
    const IS_PC_SCREEN = window.innerWidth > PC_WIDTH;   // PC画面判定

    let autoPlayFLg = true;                              // オートプレイ動作判定
    let showDotsFlg = true;                              // ドットボタン表示判定
    
    if(IS_PC_SCREEN){
        // PC画面なら
        autoPlayFLg = false;
        showDotsFlg = false;
    }

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: PC_WIDTH },
          items: 3,
          slidesToSlide: 3
        },
        tablet: {
          breakpoint: { max: PC_WIDTH, min: 464 },
          items: 2,
          slidesToSlide: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1
        }
    }

    return(
        <Carousel
            swipeable={true}
            draggable={false}
            showDots={showDotsFlg}
            arrows={false}
            responsive={responsive}
            infinite={true}
            autoPlay={autoPlayFLg}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition='transform 500ms ease-in-out'
            transitionDuration={500}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            containerClass={containerClass}
            itemClass={itemClass}
            dotListClass={dotListClass}
        >
            { dataList }
        </Carousel>
    )
}

export default InCarousel;