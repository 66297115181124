export const isUserAgent = (agent : string, targets : Array<string>) => {
   const AGENT          = agent;                    // 画面のuser-agent
   const TARGET_ARRAY   = targets;                  // 判定対象ブラウザの指定値
   const IS_EDGE        = 'edge';                   // 対象ブラウザ
   const IS_CHROME      = 'chrome';                 // 対象ブラウザ
   const IS_SAFARI      = 'safari';                 // 対象ブラウザ
   const IS_FIREFOX     = 'firefox';                // 対象ブラウザ
   const CHECK_VALUE    = -1;                       // 該当なしの戻り値判定用
   let isTarget         = false;                    // 判定対象ブラウザであればtrue

   // 各user-agentにあたるか、かつ判定対象ブラウザか判定
   TARGET_ARRAY.forEach(target => {
        if(AGENT.indexOf(IS_EDGE) !== CHECK_VALUE){
            if(IS_EDGE === target){
                isTarget = true;
            }
        }else if(AGENT.indexOf(IS_CHROME) !== CHECK_VALUE){
            if(IS_CHROME === target){
                isTarget = true;
            }
        }else if(AGENT.indexOf(IS_SAFARI) !== CHECK_VALUE){
            if(IS_SAFARI === target){
                isTarget = true;
            }
        }else if(AGENT.indexOf(IS_FIREFOX) !== CHECK_VALUE){
            if(IS_FIREFOX === target){
                isTarget = true;
            }
        }
   });

   return isTarget;
}