type values = [{
    title    : string | boolean,
    tel      : string | boolean,
    email    : string | boolean,
    password : string | boolean,
    text     : string | boolean,
    category : string | boolean,
    link     : string | boolean,
    imageFlg : string | boolean,
    file     : File | string,
}];

export const formGetValueArr = (values : values, setValue : Function, type : number, current : number, value : string | boolean, file : File | string) => {
    switch(type){
        case 1 :
            values[current].title = value;
            break;
        case 2 :
            values[current].tel = value;
            break;
        case 3:
            values[current].email = value;
            break;
        case 4 :
            values[current].password = value;
            break;
        case 5 :
            values[current].text = value;
            break;
        case 6 :
            values[current].category = value;
            break;
        case 7 :
            values[current].link = value;
            break;
        case 9 :
            values[current].imageFlg = value;
            values[current].file = file;
            break;
    }

    setValue(values);
}