// 一度別データから参照すること（直接MwnuMaterを読み込みしない）

//データ
import { ImageData } from './common/ImageData';

// メニューリスト
export const LinkMaster = {
    HOME : {
        name : 'HOME',
        link : '/',
        icon : ImageData.HomeLogo50.src,
        alt  : ImageData.HomeLogo50.alt,
    },
    LOGIN : {
        name : 'LOGIN',
        link : '/login',
    },
    TWITTER : {
        name : 'TWITTER',
        link : 'https://twitter.com/okkk_19',
        icon : ImageData.Twitter.src,
        alt  : ImageData.Twitter.alt,
    },
    HOME_ICON : {
        name : 'HOME',
        link : '/',
        icon : ImageData.Logo30.src,
        alt  : ImageData.Logo30.alt,
    },
    CONTACT : {
        name : 'CONTACT',
        link : '/contact',
        icon : ImageData.Contact30.src,
        alt  : ImageData.Contact30.alt,
    },
    PROFILE : {
        name : 'PROFILE',
        link : '/profile',
        icon : ImageData.Profile30.src,
        alt  : ImageData.Profile30.alt,
    },
    PRIVACY_POLICY : {
        name : 'PRIVACY POLICY',
        link : '/privacy_policy',
    },
    DISCLAIMER : {
        name : 'DISCLAIMER',
        link : '/disclaimer',
    },
    BLOG_LIST : {
        name : 'BLOG LIST',
        link : '/blog_list',
        icon : ImageData.Blog30.src,
        alt  : ImageData.Blog30.alt,
    },
    BLOG_DETAIL : {
        name : 'BLOG DETAIL',
        link : '/blog_detail',
    },
    OWNER : {
        name : 'OWNER',
        link : '/owner',
    },
    BLOG_INPUT : {
        name : 'BLOG INPUT',
        link : '/owner/blog/input',
    },
    BLOG_EDIT : {
        name : 'BLOG EDIT',
        link : '/owner/blog/edit',
    },
    OWNER_BLOGS : {
        name : 'BLOGS',
        link : '/owner/blogs',
    },
    OWNER_CONTACTS : {
        name : 'CONTACTS',
        link : '/owner/contacts',
    },
    HLEP : {
        name : 'HELP',
        link : '/owner/help',
    },
};