// モジュール
import emailjs from '@emailjs/browser';

// データ
import { emailApp } from '../../emailjs';

export const emailSend = (switching : string, params : any) => {
    const publicKey = emailApp.userId;
    let templateId : string | undefined = '';
    let serviceId  : string | undefined = '';

    switch(switching){
        case 'CONTACT' :
            templateId = emailApp.contact.templateId;
            serviceId  = emailApp.contact.serviceId;
            break;
        case 'CONTACT_REPLY' :
            templateId = emailApp.contactReply.templateId;
            serviceId  = emailApp.contactReply.serviceId;
            break;
    }

    if(serviceId && templateId && publicKey){
        emailjs.send(serviceId, templateId, params, publicKey)
        .then(()=>{})
        .catch(() => {});
    };
}