// モジュール
import React from 'react';
import { Link } from 'react-router-dom';
import { logEvent } from "firebase/analytics";

// データー
import { fbApp } from '../../firebase';

// スタイル
import '../../style/common/modal.css';

function Modal({ props } : any){
    const { title, parent, lists, viewFlg, setViewFlg, switching } = props;

    const analytics = fbApp.analytics;
    
    // ブログカテゴリ用
    const blogCategory = lists.map((list : any) => {
        // 初期値または投稿なしの場合はループしない
        if(list.value === lists[0].value || list.count <= 0) return(<li key={ list.value } style={{ display:'none' }}></li>);

        return(
            <li key={ list.value } className='modal_list'>
                <Link
                    to={ `${ parent }/${ list.value }` }
                    onClick={ () => { 
                        closeModal();
                        logEvent(analytics, 'search', {
                            content_type    : 'link',
                            content_id      : list.value,
                            items : [{name : list.name}]
                        })
                        } }
                >
                    { list.name }
                </Link>
                <p>({ list.count })</p>
            </li>
        )
    });

    // モーダルのボタン表示切り替え
    let modalBtns : any;
    switch(switching){
        case 'blog_category' : 
            modalBtns = blogCategory;
        break;
    };

    // モーダルを閉じる
    const closeModal = () => {
        setViewFlg(false);
    };

    return(
        <aside>
            <section className={ `Modal ${ viewFlg ? '' : 'hidden' }` } >
                <h6>{ title }</h6>

                <nav>
                    <ul>
                        { modalBtns }
                    </ul>
                </nav>

                <div
                    className='modal_close'
                    onClick={ () => { closeModal() } }
                >CLOSE</div>
            </section>
            
            <section
                className={ `overray ${ viewFlg ? '' : 'hidden'}`} 
                onClick={ () => { closeModal() } }
            ></section>
        </aside>
    )
}

export default Modal;