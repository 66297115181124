import { FirestoreDataConverter } from 'firebase/firestore';

// 型定義
export type MaxBlogId = {
    blogId : number
};

// Firestore用値変換
export const MaxBlogIdConverter : FirestoreDataConverter<MaxBlogId> = {
    // Firestoreへ送信用
    toFirestore: (to) => {
        return {
            blogId : to.blogId
        };
    },

    // Firestoreから取得用
    fromFirestore : (snapshot)=> {
        return { blogId : snapshot.data().blogId };
    },
}