// モジュール
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// コンテキスト
import { useAuthContext } from '../../context/auth/FireAuthContext';

// コンポーネント
import TopScrollView from '../../common/TopScrollView';
import Loading from '../../common/Loading';
import Btns from '../../common/Btns';

// メソッド
import { metaChange } from '../../../method/common/metaChange';
import { format } from '../../../method/common/format';
import { getContacts } from '../../../method/firebase/info/getContacts';

// データ
import { Contacts } from '../../../data/firebase/info/Contacts';

// スタイル
import '../../../style/owner/info/contacts.css';

function OwnerContacts(){
    const pageTitle     = 'CONTACTS';
    const description   = '';

    // 認証ユーザー取得
    const { user } = useAuthContext();
    const userId =  user.uid;
    
    const [contacts, setContacts] = useState<Contacts[]>([]);
    const [openId, setOpenId] = useState('');

    // 各問い合わせの展開処理
    const clickToggle = (id : string) => {
        let contactId = '';
        if(openId === ''){
            contactId = id;
        }else if(openId !== id){
            contactId = id;
        }

        setOpenId(contactId);
    };

    useEffect(() => {
        getContacts(userId, setContacts);
    }, [userId]);

    // データ取得までローディング
    if(!contacts){
        return <Loading />
    };

    const contactList = contacts.map((list : any) => {
        const openFlg = list.id === openId;     // コメントの展開判定
        const replyFlg = list.ownerText !== ''; // 返信テキスト有無の判定

        return(
            <li key={ list.id } className='contact'>
                <div>
                    <div className='contact_default_top'>
                        <div className='date'>
                            <h3>Date</h3>
                            <p>{ list.date }</p>
                        </div>

                        <span className='label'>{ replyFlg ? 'Replied' : 'Not Replied' }</span>

                        <div className={`contact_tap ${ openFlg ? 'up' : ''}` } onClick={ () => { clickToggle(list.id) } }>
                            <i className='first'></i>
                            <i className='second'></i>
                        </div>
                    </div>

                    <div className='user_text'>
                        <h3>Contact Text</h3>
                        <p>{ openFlg ? list.userText : format.textCut(list.userText, 50) }</p>
                    </div>
                </div>

                <div className={`contact_all ${ openFlg ? '' : 'hidden'}` }>
                    <div className='name'>
                        <h3>Name</h3>
                        <p>{ list.name }</p>
                    </div>

                    <div className='subject'>
                        <h3>Subject</h3>
                        <p>{ list.subject }</p>
                    </div>

                    <div className='ower_text'>
                        <h3>Reply Text</h3>
                        { replyFlg ? <p>{ list.ownerText }</p> : <Link to={`/owner/contactReply/${ list.id }`}>返信する</Link> }                        
                    </div>
                </div>
            </li>
        );
    });

    // metaタグセット
    metaChange({
        title       : pageTitle,
        description : description,
        noIndexFlg  : true
    });

    return(
        <main className='OwnerContacts'>
            <h1>{ pageTitle }</h1>

            <section>
                <nav>
                    <ul>
                        { contactList }
                    </ul>
                </nav>
            </section>

            <Btns props={ 'OWNER' } />
            
            {/* トップスクロールボタン */}
            <TopScrollView />
        </main>
    )
}

export default OwnerContacts;