// 文字数制限
const textCut = (text : string, length : number) => {
    return text.length > length ? text.substring(0 , length) + '...' : text;
};

// リンク生成
const createLink = (first : string = '', second : string = '', third : string = '') => {
    let link = '';

    if(first !== ''){
        link += first;
    }

    if(second !== ''){
        link += '/' + second;
    }

    if(third !== ''){
        link += '/' + third;
    } 
    
    return link;
};

// srcセット
const setSrc = (
    userId      : string = '',
    ref1        : string = '',
    ref2        : string = '',
    filename    : string = '',
    ) => {
        const HOST = `https://firebasestorage.googleapis.com/v0/b/${ process.env.REACT_APP_FIREBASE_PROJECT_ID }.appspot.com/o/`;
        const REF = `${ userId }%2F${ ref1 }%2F${ ref2 }%2F${ filename }?alt=media`;
        const SRC = HOST + REF;
        return SRC;
}

// 日本日付フォーマット
const jaDate = (date : any) => {
    const DT    = new Date(date.toDate());
    return `${ DT.getFullYear() }-${ DT.getMonth() + 1 }-${ DT.getDate() }`;
}

// UTC日時フォーマット
const utcDateTime = (date : any) => {
    const DT    = new Date(date.toDate());

    const UTC_DT = new Date(Date.UTC(
        DT.getFullYear(),
        DT.getMonth(),
        DT.getDate(),
        DT.getHours(),
        DT.getMinutes(),
        DT.getSeconds()
    ));
    return UTC_DT.toISOString().split('Z')[0] + '+09:00';
}

export const format = {
    textCut,
    createLink,
    setSrc,
    jaDate,
    utcDateTime,
}