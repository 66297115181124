type values = {
    title    : string | boolean,
    tel      : string | boolean,
    email    : string | boolean,
    password : string | boolean,
    text     : string | boolean,
    category : string | boolean,
    imageFlg : string | boolean,
    file     : File | string,
}

export const formGetValueObj = (values : values, setValue : Function, type : number, disabredCheck : Function | undefined, value : string | boolean, file : File | string) => {
    switch(type){
        case 1 :
            values.title = value ;
            break;
        case 2 :
            values.tel = value;
            break;
        case 3:
            values.email = value;
            break;
        case 4 :
            values.password = value;
            break;
        case 5 :
            values.text = value;
            break;
        case 6 :
            values.category = value;
            break;
        case 9 :
            values.imageFlg = value;
            values.file = file;
            break;
    }

    // 更新値セット
    setValue(values);
    
    // ボタン制御用
    if(disabredCheck !== undefined){
        disabredCheck(values)
    }
}