// モジュール
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// コンポーネント
import HeaderMenu from './HeaderMenu';

// データ
import { HeaderHomeLink } from '../../../data/common/HeaderData';

// スタイル
import '../../../style/common/header.css';

let scrollTop = 0; // スクロール量初期化

function Header(){
    const [openFlg, setOpenFlg] = useState(false);      // ヘッダーメニュ表示制御フラグ
    const body = document.querySelector('body');        // body要素取得

    const headerMenuOpen = () => {                      // ヘッダーメニュー表示処理
        setOpenFlg(true);
        if(body !== null){
            scrollTop = window.scrollY;
            body.style.position = 'fixed';
            body.style.top = `${-scrollTop}px`;
        }
    }
    
    const headerMenuClose = () => {                     // ヘッダーメニュー非表示処理
        setOpenFlg(false);
        if(body !== null){
            body.style.position = '';   
            body.style.top = '';
            window.scrollTo(0, scrollTop);
        }
    }

    const field = {                                     // 子コンポーネント用変数
        open : {
            flg   : openFlg,
            close : headerMenuClose
        }
    }

    const clickMenu = () => {
        const res = !openFlg;                           // フラグを反転

        if(body !== null){
            if(res){                                    // メニュー表示中に背景をスクロールさせない
                headerMenuOpen();
            }else{
                headerMenuClose();
            }
        }

        setOpenFlg(res);
    }

    return(
        <header className="Header">
            <section className='header_bar'>
                <div className="header_logo">
                    <Link to={ HeaderHomeLink.link } onClick={ () => {field.open.close()} }>
                        <img src={ HeaderHomeLink.icon } alt={ HeaderHomeLink.alt } />
                        <p className={ openFlg ? 'open' : '' }>{ process.env.REACT_APP_SITE_NAME }</p>
                    </Link>
                </div>

                <div className="header_menu">
                    <div id="header_menu_btn" onClick={ ()=>{clickMenu()} } className={ openFlg ? 'open' : '' }>
                        <i className={ openFlg ? 'open' : '' }></i>
                        <i className={ openFlg ? 'open' : '' }></i>
                        <i className={ openFlg ? 'open' : '' }></i>
                    </div>
                </div>
            </section>

            <HeaderMenu props={field}/>
        </header>
    )
}

export default Header;