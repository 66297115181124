import { FirestoreDataConverter } from 'firebase/firestore';

// 型定義
export type BlogsCount = {
    count : number;
};

// Firestore用値変換
export const BlogsCountConverter : FirestoreDataConverter<BlogsCount> = {
    // Firestoreへ送信用
    toFirestore: (blog) => {
        return {
            count : blog.count
        };
    },

    // Firestoreから取得用
    fromFirestore : (snapshot)=> {
        const blogCount = snapshot.data();

        return {
            count : blogCount.count,
        }
    },
};