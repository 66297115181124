// モジュール
import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

// コンポーネント
import { metaChange } from '../../method/common/metaChange';
import FormInput from '../form/FormInput';

function Login(){
    const pageTitle = 'LOGIN';
    const pageDesc  = 'ログイン画面';

    // メールアドレス
    const [email, setEmail] = useState<string>('');                  // 取得用
    const [emailFlg, setEmailFlg] = useState<boolean>(true);         // エラー判定
    //  パスワード
    const [password, setPassword] = useState<string>('');            // 取得用
    const [passwordFlg, setPasswordFlg] = useState<boolean>(true);   // エラー判定
    // ログイン失敗フラグ
    const [loginErrFlg, setLoginErrFlg] = useState<boolean>(false);
    // リダイレクト
    const navi = useNavigate();

    // ログインボタンクリックイベント
    const handleSubmit = (event : any) => {
        event.preventDefault();

        if(emailFlg || passwordFlg){ // いずれかtrueなら以後処理なし
            return;
        } 

        const auth = getAuth();

        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            return navi('/owner'); // オーナー画面へリダイレクト
        })
        .catch((error) => {
            setLoginErrFlg(true); // ログイン失敗メッセージ表示
        });
    };
    
    // metaタグセット
    metaChange({
        title       : pageTitle,
        description : pageDesc,    
        url         : window.location.href,
        noIndexFlg  : true
    });

    return(
        <main>
            <h1 className='form_title'>{ pageTitle }</h1>

            <form onSubmit={ handleSubmit }>

                <FormInput props={{
                    label          : 'Email',
                    type           : 'email',
                    getMethod      : setEmail,
                    judgeMethod    : setEmailFlg,
                    length         : 200,
                    placeholderFlg : false,
                    requiredFlg    : true,
                }} />

                <FormInput props={{
                    label          : 'Password',
                    type           : 'password',
                    getMethod      : setPassword,
                    judgeMethod    : setPasswordFlg,
                    length         : 200,
                    placeholderFlg : false,
                    requiredFlg    : true,
                }} />

                <section className={ `login_err ${ loginErrFlg ? '' : 'hidden' }` }>
                    <p>ログインに失敗しました。</p>
                </section>

                <button
                    type='submit'
                    disabled={ emailFlg || passwordFlg }
                >ログイン</button>
            </form>
        </main>
    )
}

export default Login;