export const BLOG_VALUES = {
    userId      : '',
    created_at  : '',
    updated_at  : '',
    createdDate : '',
    createdUtc  : '',
    updatedDate : '',
    updatedUtc  : '',
    imageFlg    : false,
    title       : '',
    description : '',
    category    : '',
    image1Flg   : false,
    title1      : '',
    text1       : '',
    image2Flg   : false,
    title2      : '',
    text2       : '',
    image3Flg   : false,
    title3      : '',
    text3       : '',
    image4Flg   : false,
    title4      : '',
    text4       : '',
    refeTitle1  : '',
    refeLink1   : '',
    refeTitle2  : '',
    refeLink2   : '',
}