import { FirestoreDataConverter } from 'firebase/firestore';
import { format } from '../../../method/common/format';

// 型定義
export type Blog = {
    userId      : string;
    createdDate : string;
    createdUtc  : string;
    updatedDate : string;
    updatedUtc  : string;
    imageFlg    : boolean;
    title       : string;
    description : string;
    category    : string;
    image1Flg   : boolean;
    title1      : string,
    text1       : string,
    image2Flg   : boolean;
    title2      : string,
    text2       : string,
    image3Flg   : boolean;
    title3      : string,
    text3       : string,
    image4Flg   : boolean;
    title4      : string,
    text4       : string
    refeTitle1  : string,
    refeLink1   : string,
    refeTitle2  : string,
    refeLink2   : string,
};

// Firestore用値変換
export const BlogConverter : FirestoreDataConverter<Blog> = {
    // Firestoreへ送信用
    toFirestore: (blog) => {
        return {
            userId      : blog.userId,
            imageFlg    : blog.imageFlg,
            title       : blog.title,
            description : blog.description,
            category    : blog.category,
            image1Flg   : blog.image1Flg,
            title1      : blog.title1,
            text1       : blog.text1,
            image2Flg   : blog.image2Flg,
            title2      : blog.title2,
            text2       : blog.text2,
            image3Flg   : blog.image3Flg,
            title3      : blog.title3,
            text3       : blog.text3,
            image4Flg   : blog.image4Flg,
            title4      : blog.title4,
            text4       : blog.text4,
            refeTitle1  : blog.refeTitle1,
            refeLink1   : blog.refeLink1,
            refeTitle2  : blog.refeTitle2,
            refeLink2   : blog.refeLink2,
        };
    },

    // Firestoreから取得用
    fromFirestore : (snapshot)=> {
        const BLOG = snapshot.data();

        return {
            userId      : BLOG.userId,
            created_at  : BLOG.created_at,
            updated_at  : BLOG.updated_at,
            createdDate : format.jaDate(BLOG.created_at),
            createdUtc  : format.utcDateTime(BLOG.created_at),
            updatedDate : format.jaDate(BLOG.updated_at),
            updatedUtc  : format.utcDateTime(BLOG.updated_at),
            imageFlg    : BLOG.imageFlg,
            title       : BLOG.title,
            description : BLOG.description,
            category    : BLOG.category,
            image1Flg   : BLOG.image1Flg,
            title1      : BLOG.title1,
            text1       : BLOG.text1,
            image2Flg   : BLOG.image2Flg,
            title2      : BLOG.title2,
            text2       : BLOG.text2,
            image3Flg   : BLOG.image3Flg,
            title3      : BLOG.title3,
            text3       : BLOG.text3,
            image4Flg   : BLOG.image4Flg,
            title4      : BLOG.title4,
            text4       : BLOG.text4,
            refeTitle1  : BLOG.refeTitle1,
            refeLink1   : BLOG.refeLink1,
            refeTitle2  : BLOG.refeTitle2,
            refeLink2   : BLOG.refeLink2,
        }
    },
};