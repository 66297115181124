// スクロールでコンテンツ表示
export const scrollView = (selector : any) => {
    const target = document.querySelectorAll(`.${selector}`);
    const targetArray = Array.prototype.slice.call(target);

    const options = {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: 0
    };
    
    const callback = (entries : any) => {
        entries.forEach((entry : any) => {
            if(entry.isIntersecting) {
                entry.target.classList.remove('hidden');
            }
        });
    };
    
    const observer = new IntersectionObserver(callback, options);
    
    targetArray.forEach(tgt => {
        observer.observe(tgt)
    });
}