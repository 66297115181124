// モジュール
import React, { useEffect, useState } from 'react';
import { Timestamp, doc, increment, writeBatch } from "firebase/firestore";
import { useNavigate, useParams } from 'react-router-dom';

// コンテキスト
import { useAuthContext } from '../../context/auth/FireAuthContext';

// コンポーネント
import ErrorPages from '../../common/ErrorPages';
import Btns from '../../common/Btns';
import FormInputs from '../../form/FormInputs';
import FormTexts from '../../form/FormTexts';
import FormImages from '../../form/FormImages';
import FormSelects from '../../form/FormSelects';

// メソッド
import { metaChange } from '../../../method/common/metaChange';
import { IMAGE_UP_DEL } from '../../../method/firebase/common/imageUpDel';
import { getMaxBlogId } from '../../../method/firebase/blog/getMaxBlogId';
import { getBlog } from '../../../method/firebase/blog/getBlog';
import { format } from '../../../method/common/format';

// データ
import  { fbApp } from '../../../firebase';
import { BlogData, CategoryList } from '../../../data/common/BlogData';
import { BLOG_VALUES } from '../../../data/blog/Blog';

// スタイル
import '../../../style/form/form.css';

function BlogInput(){
    const BLOG_ID          = Number(useParams().blogId);
    const IS_EDIT          = !isNaN(BLOG_ID);
    const FIRESTORE        = fbApp.firestore;
    const COLLECTION_BLOG  = BlogData.BLOGS;

    // ブログデータ
    const [blog, setBlog]  = useState({ status : '', data : BLOG_VALUES }); 
    // blogIdの取得
    const [blogId, setBlogId] = useState<number>(BLOG_ID);
    
    useEffect(() => {
        // ブログデータ取得またはblogIdの最高値取得
        IS_EDIT ? getBlog(FIRESTORE, setBlog, BLOG_ID) : getMaxBlogId(FIRESTORE, COLLECTION_BLOG, setBlogId);
    }, [IS_EDIT, BLOG_ID, FIRESTORE, COLLECTION_BLOG]);

    const PAGE_DESC        = '';
    const TITLE_LENGTH     = 35;
    const DESC_LENTGTH     = 100;
    const REFE_LENTGH      = 100;
    const TEXT_LENGTH      = 1000;
    const CATEGORYS        = CategoryList;
    const CATEGORY_DEF_VAL = CATEGORYS[0].value;
    const ERROR_CONNECTION = <ErrorPages props={ 'CONNECTION' } />;
    const USDER_ID         = useAuthContext().user.uid;
    const navi             = useNavigate();
    const BLOG_DATAS       = blog.data;
    
    let pageTitle          = 'BLOG INPUT';  // 画面タイトル

    // 編集画面
    if(IS_EDIT){
        pageTitle = 'BLOG EDIT';
    }

    // メインデータ
    const FORM_MAINS = {
        title    : BLOG_DATAS.title,
        text     : BLOG_DATAS.description,
        category : BLOG_DATAS.category,
        imageFlg : BLOG_DATAS.imageFlg,
        file     : '',
    }
    const [mains, setMains] = useState(FORM_MAINS);

    // 詳細データ
    const FORM_DETAILS = [
        {title : BLOG_DATAS.title1, text : BLOG_DATAS.text1, imageFlg : BLOG_DATAS.image1Flg, file : ''},
        {title : BLOG_DATAS.title2, text : BLOG_DATAS.text2, imageFlg : BLOG_DATAS.image2Flg, file : ''},
        {title : BLOG_DATAS.title3, text : BLOG_DATAS.text3, imageFlg : BLOG_DATAS.image3Flg, file : ''},
        {title : BLOG_DATAS.title4, text : BLOG_DATAS.text4, imageFlg : BLOG_DATAS.image4Flg, file : ''},
    ];
    const [details, setDetails] = useState(FORM_DETAILS);

    // リンクデータ
    const FORM_REFERENCES = [
        {title : BLOG_DATAS.refeTitle1, link : BLOG_DATAS.refeLink1},
        {title : BLOG_DATAS.refeTitle2, link : BLOG_DATAS.refeLink2},
    ];
    const [refes, setRefes] = useState(FORM_REFERENCES);

    // ボタン制御
    const [disabledFlg, setDisabledFlg] = useState(true);

    // ボタン制御チェック
    const blogDisabredCheck = (values : {title : string, text : string, category : string, imageFlg : boolean}) => {
        const RES = values.title === ''
            || values.text === ''
            || values.category === CATEGORY_DEF_VAL
            || values.imageFlg === false
            ;

        setDisabledFlg(RES);
    }

    // 送信処理
    const handleSubmit = (event : any) => {
        event.preventDefault();

        // trueなら以後処理なし
        if(disabledFlg){ 
            return;
        }
        
        // 現在日時取得
        const DATE_NOW = Timestamp.fromDate(new Date());

        // ブログデータ格納変数
        const BLOG_DATA = {
            userId      : USDER_ID,
            updated_at  : DATE_NOW,
            title       : mains.title,
            imageFlg    : mains.imageFlg,
            category    : mains.category,
            description : mains.text,
        }

        // 詳細データ処理
        details.forEach((detail, index) => {
            const CURRENT_INDEX = index + 1;

            eval('BLOG_DATA.image' + CURRENT_INDEX +'Flg=detail.imageFlg');
            eval('BLOG_DATA.title' + CURRENT_INDEX + '=detail.title');
            eval('BLOG_DATA.text'  + CURRENT_INDEX + '=detail.text');
        });

        // 参考リンクデータ処理
        refes.forEach((refe, index) => {
            const CURRENT_INDEX = index + 1;

            eval('BLOG_DATA.refeTitle' + CURRENT_INDEX + '=refe.title');
            eval('BLOG_DATA.refeLink'  + CURRENT_INDEX + '=refe.link');
        });

        // データ保存処理
        try {
            // バッチ開始
            const batch = writeBatch(FIRESTORE);

            let blogIdInt = 0;
            let blogIdStr = '';

            if(IS_EDIT){
                // ブログ更新

                // blogIdセット
                blogIdInt = blogId;
                blogIdStr = String(blogIdInt);

                // 更新処理
                const blogsRef = doc(FIRESTORE, COLLECTION_BLOG, blogIdStr);
                batch.update(blogsRef, BLOG_DATA);
                
                // カウント更新処理
                const NEW_CATEGORY = BLOG_DATA.category;
                const OLD_CATEGORY = FORM_MAINS.category;
                if(NEW_CATEGORY !== OLD_CATEGORY && NEW_CATEGORY !== CATEGORY_DEF_VAL){
                    // カウント加算処理
                    const BlogsCountUpRef = doc(FIRESTORE, BlogData.COUNT, NEW_CATEGORY);
                    batch.update(BlogsCountUpRef, {
                        userId    : USDER_ID,
                        count     : increment(1)
                    });

                    // カウント減産処理
                    const BlogsCountDonwRef = doc(FIRESTORE, BlogData.COUNT, OLD_CATEGORY);
                    batch.update(BlogsCountDonwRef, {
                        userId    : USDER_ID,
                        count     : increment(-1)
                    });
                };
            }else{
                // ブログ登録

                // blogIdセット
                blogIdInt = blogId > 0 ? blogId + 1 : 1;
                blogIdStr = String(blogIdInt);
                BLOG_DATA.blogId = blogIdInt;
                BLOG_DATA.created_at = DATE_NOW;

                // 登録処理
                const blogsRef = doc(FIRESTORE, COLLECTION_BLOG, blogIdStr);
                batch.set(blogsRef, BLOG_DATA);
    
                // カウント登録処理
                const BlogsCountRef = doc(FIRESTORE, BlogData.COUNT, BLOG_DATA.category);

                batch.update(BlogsCountRef, {
                    userId    : USDER_ID,
                    count     : increment(1)
                });
                
            }

            // 画像アップロード
            // メイン画像
            if(BLOG_DATA.imageFlg && mains.file !== ''){
                IMAGE_UP_DEL.imageUpload(
                    fbApp.storage,
                    USDER_ID,
                    BlogData.FILENAME,
                    mains.file,
                    ERROR_CONNECTION,
                    COLLECTION_BLOG,
                    blogIdStr
                )
            }
            // 詳細画像
            details.forEach((detail, index) => {
                if(detail.imageFlg && detail.file !== ''){
                    IMAGE_UP_DEL.imageUpload(
                        fbApp.storage,
                        USDER_ID,
                        BlogData.FILE_NAME + (index + 1) + BlogData.FILE_EXTEND,
                        detail.file,
                        ERROR_CONNECTION,
                        COLLECTION_BLOG,
                        blogIdStr
                    );
                }
            });

            // バッチ終了
            batch.commit();

            return navi('/owner'); // オーナー画面へリダイレクト
        } catch(e) {
            return navi('/error/connection');
        };
    };

    // 更新画面用初期画像パス設定
    const setSrc = (isEdit : boolean, blogFlg : boolean, filename = '') => {
        if(isEdit && blogFlg){
            return format.setSrc(USDER_ID, BlogData.BLOGS, String(blogId), filename);
        }
        return '';
    }

    // メインデータ取得フォーム
    const FORM_MAINS_VIEW = (
        <section>
            <FormImages 
                props={{
                    LABEL         : 'IMAGE',
                    TYPE          : 'file',
                    IMAGE_FLG     : FORM_MAINS.imageFlg,
                    VALUE         : setSrc(IS_EDIT, FORM_MAINS.imageFlg, BlogData.FILENAME), 
                    setValues     : setMains,
                    VALUE_TYPE    : 9,
                    VALUES        : mains,
                    CURRENT       : -1,
                    disabredCheck : blogDisabredCheck,
                }}
            />

            <FormInputs
                props={{
                    LABEL               : 'TITLE',
                    TYPE                : 'text',
                    VALUE               : FORM_MAINS.title,
                    VALUES              : mains,
                    setValues           : setMains,
                    VALUE_TYPE          : 1,
                    CURRENT             : -1,
                    MAX_LENGTH          : TITLE_LENGTH,
                    PLACE_HOLDER_FLG    : true,
                    disabredCheck       : blogDisabredCheck,
                }}
            />

            <FormTexts 
                props={{
                    LABEL            : 'DESCRIPTION',
                    VALUE            : FORM_MAINS.text,
                    VALUES           : mains,
                    setValues        : setMains,
                    VALUE_TYPE       : 5,
                    CURRENT          : -1,
                    MAX_LENGTH       : DESC_LENTGTH,
                    PLACE_HOLDER_FLG : true,
                    disabredCheck    : blogDisabredCheck,
                }}
            />

            <FormSelects
                props={{
                    LABEL         : 'CATEGORY',
                    VALUE         : FORM_MAINS.category,
                    OPTIONS       : CATEGORYS,
                    setValues     : setMains,
                    VALUE_TYPE    : 6,
                    VALUES        : mains,
                    CURRENT       : -1,
                    disabredCheck : blogDisabredCheck,
                }}
            />
        </section>
    );
    // 詳細データ取得フォーム
    const FORM_DETAILS_VIEW = FORM_DETAILS.map((detail, index) => {
        const LABEL_INDEX = index + 1;

        return (
            <section key={index}>
                <FormImages 
                    props={{
                        LABEL       : 'DETAIL IMAGE' + LABEL_INDEX,
                        TYPE        : 'file',
                        IMAGE_FLG   : detail.imageFlg,
                        VALUE       : setSrc(IS_EDIT, detail.imageFlg, BlogData.FILE_NAME + LABEL_INDEX + BlogData.FILE_EXTEND), 
                        setValues   : setDetails,
                        VALUE_TYPE  : 9,
                        VALUES      : details,
                        CURRENT     : index,
                    }}
                />

                <FormInputs
                    props={{
                        LABEL               : 'DETAIL TITLE' + LABEL_INDEX,
                        TYPE                : 'text',
                        VALUE               : detail.title,
                        VALUES              : details,
                        setValues           : setDetails,
                        VALUE_TYPE          : 1,
                        CURRENT             : index,
                        MAX_LENGTH          : TITLE_LENGTH,
                        PLACE_HOLDER_FLG    : true,
                    }}
                />

                <FormTexts 
                    props={{
                        LABEL               : 'DETAIL TEXT' + LABEL_INDEX,
                        VALUE               : detail.text,
                        VALUES              : details,
                        setValues           : setDetails,
                        VALUE_TYPE          : 5,
                        CURRENT             : index,
                        MAX_LENGTH          : TEXT_LENGTH,
                        PLACE_HOLDER_FLG    : true,
                    }}
                />
            </section>
        )
    });
    
    // リンクデータ取得フォーム
    const FORM_REFERNCES_VIEW = FORM_REFERENCES.map((refe,index) => {
        const LABEL_INDEX = index + 1;

        return (
            <section key={index}>
                <FormInputs
                    props={{
                        LABEL               : 'REFERNCE TITLE' + LABEL_INDEX,
                        TYPE                : 'text',
                        VALUE               : refe.title,
                        VALUES              : refes,
                        setValues           : setRefes,
                        VALUE_TYPE          : 1,
                        CURRENT             : index,
                        MAX_LENGTH          : REFE_LENTGH,
                        PLACE_HOLDER_FLG    : true,
                    }}
                />

                <FormInputs
                    props={{
                        LABEL               : 'REFERNCE LINK' + LABEL_INDEX,
                        TYPE                : 'url',
                        VALUE               : refe.link,
                        VALUES              : refes,
                        setValues           : setRefes,
                        VALUE_TYPE          : 7,
                        CURRENT             : index,
                        MAX_LENGTH          : REFE_LENTGH,
                        PLACE_HOLDER_FLG    : true,
                    }}
                />
            </section>
        )
    });

    // metaタグセット
    metaChange({
        title       : pageTitle,
        description : PAGE_DESC,    
        noIndexFlg  : true
    });

    return(
        <main>
            <h1 className='form_title'>{ pageTitle }</h1>

            <form onSubmit={ handleSubmit } encType="multipart/form-data">
                <h2>入力</h2>

                {/* メインフォーム */}
                { FORM_MAINS_VIEW }

                {/* 詳細フォーム */}
                { FORM_DETAILS_VIEW }

                {/* 参考リンクフォーム */}
                { FORM_REFERNCES_VIEW }

                <button
                    type='submit'
                    disabled={ disabledFlg }
                >SEND</button>
            </form>

            <Btns props={ 'OWNER' } />
        </main>
    )
}

export default BlogInput;