// モジュール
import React from 'react';

// コンポーネント
import Btns from './Btns';

// メソッド
import { metaChange } from '../../method/common/metaChange';

function ErrorPages({props} : any){
    let errTitle  : string = '';
    let btn       : string = 'HOME';

    const pageDesc = 'エラー画面';

    switch(props){
        case 'CONNECTION' :
            errTitle = '接続に失敗しました。申し訳ございませんが時間をおいて、もう一度お願いいたします。';
            break;
        case 'LOGOUT' :
            errTitle = 'ログアウトに失敗しました。もう一度ログアウトをお願いいたします。';
            break;
        case 'EMAIL' :
            errTitle = 'メール送信に失敗しました。お手続き内容は受付しておりますので確認後ご連絡いたします。また、確認に少々お時間をいただいております。しばらくお待ちください。';
            break;
        case 'NOT_FOUND' :
            errTitle = 'ページが見つかりませんでした。..NOT FOUND';
            break;
        default :
            errTitle = 'ページが見つかりませんでした。';
    };

    // meta
    metaChange({
        title       : errTitle,
        description : pageDesc,    
        noIndexFlg  : true
    });

    return(
        <main 
            className='ErrorPages'
            style={{
                margin   : '150px auto 0',
                padding  : '0 20px',
            }}
        >
            <h1>{ errTitle }</h1>

           <Btns props={ btn } />
        </main>
    )
}

export default ErrorPages;