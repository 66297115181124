import { FirestoreDataConverter } from 'firebase/firestore';

// 型定義
export type CategoryBlogs = {
    blogId      : number;
    date        : string;
    title       : string;
    description : string;
};

// Firestore用値変換
export const CategoryBlogsConverter : FirestoreDataConverter<CategoryBlogs> = {
    // Firestoreへ送信用
    toFirestore: (blog) => {
        return {
            blogId      : blog.blogId,
            title       : blog.title,
            description : blog.description,
        };
    },

    // Firestoreから取得用
    fromFirestore : (snapshot)=> {
        const blog = snapshot.data();

        // 登録日付フォーマット
        const created_at = blog.created_at.toDate();
        const dt = new Date(created_at)
        const date = `${ dt.getFullYear() }-${ dt.getMonth() + 1 }-${ dt.getDate() }`;

        return {
            blogId      : blog.blogId,
            date        : date,
            title       : blog.title,
            description : blog.description,
        }
    },
};