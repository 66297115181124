// モジュール
import React from 'react';
import { useNavigate  } from 'react-router-dom';

// コンテキスト
import { logout } from '../context/auth/FireAuthContext';
import { useAuthContext } from '../context/auth/FireAuthContext';

// コンポーネント
import Btns from '../common/Btns';

// メソッド
import { metaChange } from '../../method/common/metaChange';

import { OwnerUid } from '../../data/common/OwnerData';

// スタイル
import '../../style/owner/owner.css';
import '../../style/common/btns.css';

function Owner(){
    const navigate = useNavigate();
    const { user } = useAuthContext(); 
    const userId = user.uid;

    OwnerUid.forEach(uid => {
        if(uid === userId){
            console.log('true');
        }

    })

    // metaタグセット
    metaChange({
        title       : '',
        description : '',    
        noIndexFlg  : true
    });

    return(
        <main className='Owner'>
            <Btns props={ 'BLOG_INPUT' } />
            <Btns props={ 'BLOGS' } />
            <Btns props={ 'CONTACTS' } />
            <Btns props={ 'HELP' } />

            <div className='Btns' style={ {backgroundColor : 'red'} } onClick={ () => { logout(navigate) } }>
                <p>LOGOUT</p>
            </div>
        </main>
    )
}

export default Owner;