import { FirestoreDataConverter, Timestamp } from 'firebase/firestore';

// 型定義
export type Contacts = {
    name        : string,
    email       : string,
    subject     : string,
    userText    : string,
    ownerText   : boolean,
    date        : string,
};

// Firestore用値変換
export const ContactsConverter : FirestoreDataConverter<Contacts> = {
    // Firestoreへ送信用
    toFirestore: (data) => {
        return {
            name        : data.name,
            email       : data.email,
            subject     : data.subject,
            userText    : data.userText,
            ownerText   : data.ownerText ,
        };
    },

    // Firestoreから取得用
    fromFirestore : (snapshot)=> {
        const data = snapshot.data();

        // 登録日付フォーマット
        const created_at = data.created_at.toDate();
        const dt = new Date(created_at)
        const date = `${ dt.getFullYear() }-${ dt.getMonth() + 1 }-${ dt.getDate() } ${ dt.getHours() }:${ dt.getMinutes() }:${ dt.getSeconds() }`;

        return {
            name        : data.name,
            email       : data.email,
            subject     : data.subject,
            userText    : data.userText,
            ownerText   : data.ownerText, 
            date        : date,
        }
    },
};