function nullCheck(
        value         : string,
        setErrMessage : (message : string) => void
    ){
        const res = value = '';
    
        const nullMessage = res ? '入力してくてください。' : '';

        setErrMessage(nullMessage);

        return res;
}

export default nullCheck;