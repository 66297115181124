// モジュール
import React, { useEffect } from 'react';
import CSS from 'csstype';

// コンポーネント
import { scrollView } from '../../method/common/scrollView';
import TopScrollHIdden from './TopScrollHidden';

import '../../style/common/topScroll.css';

function TopScrollView() {
    const linkBtn : CSS.Properties = {
        display: 'block',
        width: '100%',
        height: '100%',
        position : 'relative',
    };

    const firstI : CSS.Properties = {
        display : 'block',
        width : '50%',
        height : '2px',
        backgroundColor : 'red',
        transform : 'rotate(135deg)',
        position : 'absolute',
        bottom: '25px',
        right: '21px',
    };

    const secondI : CSS.Properties = {
        display : 'block',
        width : '50%',
        height : '2px',
        backgroundColor : 'red',
        transform : 'rotate(45deg)',
        position : 'absolute',
        bottom: '25px',
        right: '5px',
    };

    const clickStyle = () => {
        const html = document.querySelector('html');
        if(html){
            html.style.scrollBehavior = 'smooth';
        }
    };
    
    useEffect(() => {
        scrollView('TopScroll');
    });

    return(
        <aside>
            {/* ページトップボタン非表示 */}
            <TopScrollHIdden />

            {/* ページトップボタン表示 */}
            <div className='TopScroll hidden'>
                <a href='#top_scroll' style={linkBtn} onClick={ () => { clickStyle() } } >
                    <i style={firstI}></i>
                    <i style={secondI}></i>
                </a>
            </div>
        </aside>
    )
}

export default TopScrollView;