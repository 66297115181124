//　データ
import { LinkMaster } from '../LinkMaster';

// 定数
export const BlogData = {
    BLOGS       : 'blogs',
    FILE_NAME   : 'blog_img',
    FILE_EXTEND : '.jpg',
    FILENAME    : 'blog_img.jpg',
    FILENAME1   : 'blog_img1.jpg',
    FILENAME2   : 'blog_img2.jpg',
    FILENAME3   : 'blog_img3.jpg',
    FILENAME4   : 'blog_img4.jpg',
    COUNT       : 'blogsCount',
};

export const CategoryList = [
    {name : 'SELECT'    , value : 'select'  , text : '選択してください'},
    {name : 'EVERY'     , value : 'every'   , text : '日常'},
    {name : 'CODE'      , value : 'code'    , text : 'コード'},
    {name : 'TOURISM'   , value : 'tourism' , text : '観光'},
    {name : 'BOOK'      , value : 'book'    , text : '書籍'},
    {name : 'FINANCE'   , value : 'finance' , text : 'お金'},
];

export const BlogLinkData = {
    LIST    : LinkMaster.BLOG_LIST,
    DETAIL  : LinkMaster.BLOG_DETAIL,
}