// モジュール
import React, { useEffect, useState } from 'react';
import { Timestamp, doc, updateDoc, getDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';

// コンテキスト
import { useAuthContext } from '../../context/auth/FireAuthContext';

// コンポーネント
import TopScrollView from '../../common/TopScrollView';
import Loading from '../../common/Loading';
import Btns from '../../common/Btns';
import FormText from '../../form/FormText';
import SuccessComplete from '../../common/SuccessComplete';

// メソッド
import { metaChange } from '../../../method/common/metaChange';
import { fbApp } from '../../../firebase';
import { emailSend } from '../../../method/form/emailSend';

// データ
import { Contacts, ContactsConverter } from '../../../data/firebase/info/Contacts';

// スタイル
import '../../../style/owner/info/contacts.css';

function OwnerContactReply(){
    const pageTitle     = 'CONTACT REPLY';
    const description   = '';
    const textLength    = 140;

    // contactId取得
    const { contactId } = useParams();
    const navi = useNavigate();

    // 認証ユーザー取得
    const { user } = useAuthContext();
    const userId =  user.uid;
    
    const [contact, setContact] = useState<Contacts>();
    const [ownerText, setOwnerText] = useState<string>('');
    const [ownerTextFlg, setOwnerTextFlg] = useState(true);
    const [seccessFlg, setSuccessFlg] = useState(false);

    const disabledFlg = ownerTextFlg;

    // 問い合わせ取得
    const getContact = () => {
        if(contactId){
            const contactQuery = doc(
                fbApp.firestore,
                'contacts',
                contactId
            ).withConverter(ContactsConverter);

            getDoc(contactQuery).then((snapShoto) => {
                const data = snapShoto.data();
                
                setContact(data);
            });
        }
    };
    
    useEffect(() => {
        getContact();
    });

    // データ取得までローディング
    if(!contact){
        return <Loading />
    };

    const handleSubmit = (event : any) => {
        event.preventDefault();

        if(disabledFlg || !contactId){
            return;
        };

        try {
            // データ
            const data = {
                userId      : userId,
                ownerText   : ownerText,
                update_at   : Timestamp.fromDate(new Date()),
            };

            // 返信処理
            const contactsRef = doc(fbApp.firestore, 'contacts', contactId);
            updateDoc(contactsRef, data)
            .then(()=>{
                // 送信完了メッセージ表示
                setSuccessFlg(true);

                // メール送信
                const emailParams = {
                    toName    : contact.name,
                    toEmail   : contact.email,
                    toSubject : contact.subject,
                    userText  : contact.userText,
                    ownerText : ownerText,
                    siteName  : process.env.REACT_APP_SITE_NAME,
                    siteEmail : process.env.REACT_APP_INFO_MAIL_ADDRESS,
                };

                emailSend('CONTACT_REPLY', emailParams);
            })
            .catch(()=>{
                return navi('/error/connection');   // エラー画面へリダイレクト
            });
        } catch(e) {
            return navi('/error/connection');       // エラー画面へリダイレクト
        };
    }

    // metaタグセット
    metaChange({
        title       : pageTitle,
        description : description,
        noIndexFlg  : true
    });

    const viewPage = !seccessFlg ? (
        <section>
            <h1>{ pageTitle }</h1>

            <section className='contact'>
                <div className='date'>
                    <h3>Date</h3>
                    <p>{ contact.date }</p>
                </div>

                <div className='name'>
                    <h3>Name</h3>
                    <p>{ contact.name }</p>
                </div>

                <div className='subject'>
                    <h3>Subject</h3>
                    <p>{ contact.subject }</p>
                </div>

                <div className='email'>
                    <h3>Email</h3>
                    <p>{ contact.email }</p>
                </div>

                <div className='user_text'>
                    <h3>Contact Text</h3>
                    <p>{ contact.userText }</p>
                </div>
            </section>

            <form onSubmit={ handleSubmit } >
                <FormText props={{
                    label          : '',
                    getMethod      : setOwnerText,
                    judgeMethod    : setOwnerTextFlg,
                    length         : textLength,
                    requiredFlg    : true,
                }} />

                <button
                    type='submit'
                    disabled={ disabledFlg }
                    style={{
                        margin : '0px auto'
                    }}
                >REPLY</button>
            </form>

            <Btns props={ 'CONTACTS' } />
            
            {/* トップスクロールボタン */}
            <TopScrollView />
        </section>
    ) : <SuccessComplete props={ '/owner' } />;

    return(
        <main className='OwnerContacts'>
            { viewPage }
        </main>
    )
}

export default OwnerContactReply;