import React from 'react';

function Loading(){
    return(
        <div style={{
            paddingTop : '200px',
            textAlign: 'center',
        }}>
            <p>Loading...</p>
        </div>
    )
}

export default Loading;