// モジュール
import React, { useState, useEffect } from 'react';

// コンポーネント
import nullCheck from '../../method/form/validation/nullCheck';
import maxCheck from '../../method/form/validation/maxCheck';

// メソッド
import { formGetValueArr } from '../../method/form/formGetValueArr';
import { formGetValueObj } from '../../method/form/formGetValueObj';

function FormTexts({props} : any){
    const {
        LABEL,
        VALUE,
        setValues,
        VALUE_TYPE,
        MAX_LENGTH,
        VALUES,
        CURRENT             = 0,
        PLACE_HOLDER_FLG    = false,
        MIN_LENGTH          = 0,
        disabredCheck,
    } = props;
    // 入力必須判定
    const REQUIRED_FLG  = disabredCheck !== undefined;
    // 初期値有無判定とセット
    const DEFAULT_VALUE = VALUE !== undefined ? VALUE : '';
    
    const [isNull, setIsNull] = useState(true);         // 入力の確認用
    const [errMessage, setErrMessage] = useState('');   // エラーメッセージ用

    const getValue = (value : string) => {
        // 入力の有無結果を反映
        setIsNull(value === '');

        //文字数チェック
        if(maxCheck(value, MAX_LENGTH, setErrMessage)){
            return;
        }

        // 必須項目の場合、nullチェック
        if(REQUIRED_FLG){
            if(nullCheck(value, setErrMessage)){
                return;
            }
        }
        
        // 値を渡す
        if(CURRENT === -1){
            formGetValueObj(VALUES, setValues, VALUE_TYPE, disabredCheck, value, '');
        }else{
            formGetValueArr(VALUES, setValues, VALUE_TYPE, CURRENT, value, '');
        }
    }

    // placeholderのテキスト制御
    let phText = `最大${ MAX_LENGTH }文字`;
    if(!PLACE_HOLDER_FLG){
        phText = '';
    }else if(PLACE_HOLDER_FLG && REQUIRED_FLG){
        phText = phText + ' (必須)';
    }

    useEffect(() => {
        setIsNull(VALUE === '');
        // 初期値があれば値セット
        if(DEFAULT_VALUE !== ''){
            if(CURRENT === -1){
                formGetValueObj(VALUES, setValues, VALUE_TYPE, disabredCheck, DEFAULT_VALUE, '');
            }else{
                formGetValueArr(VALUES, setValues, VALUE_TYPE, CURRENT, DEFAULT_VALUE, '');
            }
        }
    },[
        VALUE, DEFAULT_VALUE, CURRENT, VALUES, setValues, VALUE_TYPE, disabredCheck
    ]);

    return(
        <div className='form_label'>
            <label>{ LABEL }</label>
            <textarea
                maxLength={ MAX_LENGTH }
                minLength={ MIN_LENGTH }
                placeholder={ phText }
                onChange={ e => { getValue(e.target.value) } }
                className={ `form_text ${ isNull ? 'init' : '' }` }
                required={ REQUIRED_FLG }
                defaultValue={ DEFAULT_VALUE }
            >
            </textarea>
            <p className='formError'>{ errMessage }</p>
        </div>
    )
}

export default FormTexts;