// モジュール
import React, { useState } from 'react';

// コンポーネント
import Breadcrumbs from '../common/Breadcrumbs';
import TopScrollView from '../common/TopScrollView';
import Btns from '../common/Btns';
import StructuredDataJson from '../seo/StructuredDataJson';

// メソッド
import { metaChange } from '../../method/common/metaChange';

// スタイル
import '../../style/info/common.css';

function PrivacyPolicy(){
    const pageTitle         = 'PRIVACY POLICY';                                             // 画面タイトル
    const description       = process.env.REACT_APP_SITE_NAME + 'のプライバシーポリシーの画面'; // 画面説明
    const [breadcrumbsData] = useState([{ name : pageTitle, link : '/privacy_policy' }]);   // パンくずデータ

    // metaタグセット
    metaChange({
        title       : pageTitle,
        description : description,
        url         : window.location.href,
    });

    return(
        <section className='Contents_Container'>
            {/* ぱんくず */}
            <Breadcrumbs props={{
                data : breadcrumbsData,
            }} />

            {/* メイン */}
            <main className='info'>
                <h1>{ pageTitle }</h1>

                <section className='list'>
                    <h2>個人情報の利用目的について</h2>
                    <p>当サイトでは、お問い合わせや記事へのコメントの際、お名前やメールアドレス等の個人情報の入力をお願いいただくことがございます。</p>
                    <p>取得した個人情報は、お問い合わせへの回答や電子メールでのお知らせなどで利用させていただくものであり、他の目的での利用はいたしません。</p>
                </section>

                <section className='list'>
                    <h2>個人情報の第三者開示について</h2>
                    <p>取得した個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはございません。</p>
                    <ul>
                        <li>本人の同意が得られた場合</li>
                        <li>法令により開示が求められた場合</li>
                    </ul>
                </section>

                <section className='list'>
                    <h2>CookieまたはWeb beaconの使用について</h2>
                    <p>当サイトでは、広告配信やアクセス解析のためにCookie(クッキー)とWeb beacon(ウェブビーコン)を使用しております。</p>
                    <p>Cookieまたはweb beaconはユーザーのコンピュータを識別できますが、ユーザー個人を特定できるものではございません。</p>
                    <p>Cookie等の使用を望まない場合、ブラウザから無効に設定できます。</p>
                    <p>ただし、Cookie等を無効にした場合、一部ご利用できないサービスがございます。</p>
                    <p>Googleがデータの使用に関する詳細は「<a href='https://policies.google.com/technologies/partner-sites' target='_blank' rel='noopener noreferrer'>Google のサービスを使用するサイトやアプリから収集した情報の Google による使用 – ポリシーと規約 – Google</a>」でご確認いただけます。</p>
                </section>

                <section className='list'>
                    <h2>サイト内の広告について</h2>
                    <p>当サイトでは第三者配信の広告サービス（Googleアドセンス等）を利用しております。</p>
                    <p>第三者配信事業者は、ユーザーがご興味のある広告を表示するためCookieまたはWeb beaconを使用しております。</p>
                    <p>パーソナライズ広告は、<a href='https://adssettings.google.com/authenticated' target='_blank' rel='noopener noreferrer'>広告設定</a> で無効にできます。</p>
                    <p>また、<a href='https://optout.aboutads.info/' rel='noreferrer'>www.aboutads.info</a>で第三者配信事業者のCookieを無効にすることができます。</p>
                    <p>Googleアドセンスに関する詳細は「<a href='https://policies.google.com/technologies/ads?gl=jp' target='_blank' rel='noopener noreferrer'>広告 – ポリシーと規約 – Google</a>」でご確認いただけます。</p>
                </section>

                <section className='list'>
                    <h2>アクセス解析ツールについて</h2>
                    <p>当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しております。</p>
                    <p>このGoogleアナリティクスはトラフィックデータの収集のためにCookieまたはWeb beaconを使用しております。</p>
                    <p>トラフィックデータは匿名で収集されており、個人を特定するものではありません。</p>
                    <p>Googleアナリティクスに関する詳細は「<a href='https://marketingplatform.google.com/about/analytics/terms/jp/' target='_blank' rel='noopener noreferrer'>Google アナリティクス利用規約</a>」でご確認いただけます。</p>
                </section>

                <section className='list'>
                    <h2>コメントについて</h2>
                    <p>当サイトへのお問い合わせや記事へのコメントの際、IPアドレスを収集しておりますが、スパムや荒らしへの対応以外にこのIPアドレスを使用することはありません。</p>
                    <p>コメントについては随時掲載となりますが、管理人が内容を事後確認し不適切と判断した場合には非掲載となります。あらかじめご了承ください。</p>
                </section>

                <section className='enactment'>
                    <h6>制定日</h6>
                    <p>2022/6/28</p>
                </section>

                <Btns props={ '' } />
            </main>

            {/* ぱんくず */}
            <Breadcrumbs props={{
                data : breadcrumbsData,
            }} />

            {/* トップスクロールボタン */}
            <TopScrollView />

            {/* 構造化データ */}
            <StructuredDataJson props={{
                breadcrumbs : breadcrumbsData
            }} />
        </section>
    )
}

export default PrivacyPolicy;