// モジュール
import React from 'react';
import { Link } from 'react-router-dom';

function Breadcrumbs({ props } : any){
    const { data, isHeight = '' } = props;
    const BREADCRUMB_DATA   = data;
    const IS_HEIGHT         = isHeight;

    // HOMEのURL
    const HOME_URL = '/';

    // パンくずのアイテム
    const BREADCRUMB_ITEMS = BREADCRUMB_DATA ? [
        { 'name' : 'HOME', 'link' : HOME_URL},
        ...BREADCRUMB_DATA
    ] : [
        { 'name' : 'HOME', 'link' : HOME_URL},
    ];

    // パンくずのアイテムを構造化データ型式に整形
    const BREADCRUMB_ITEM_LIST = BREADCRUMB_ITEMS.map((list : any, index : number) => {
        let itemUrl = list.link;        // アイテムにセットするURL
        const ITEM_NAME = list.name;    // アイテムにセットするテキスト
        let breadcrumbsDom = (          // リンクタグとpタグ
            <div key={ index }>
                    <Link
                        to={ itemUrl }
                        style={{
                            display         : 'inline',
                            padding         : '0 10px',
                            textDecoration  : 'underline',
                            color           : 'blue'
                        }}
                    >{ ITEM_NAME }</Link>
                <span>/</span>
            </div>
        );

        // リンクと表示ページ切り替え
        if(BREADCRUMB_ITEMS.length - 1 <= index){ // 配列最後
            itemUrl = '';
            breadcrumbsDom = (
                <p
                    key={ index }
                    style={{ padding : '0 10px' }}
                >{ ITEM_NAME }</p>
            );
        }else if(index > 0){ // 配列2番目以降
            itemUrl = HOME_URL + itemUrl;
        }

        return breadcrumbsDom;
    });

    return(
        <aside style={{
            display         : 'flex',
            flexWrap        : 'wrap',
            padding         : '30px 0',
            fontSize        : '18px',
            letterSpacing   : '1.5px',
            lineHeight      : '30px',
            height          : IS_HEIGHT
        }} >
            { BREADCRUMB_ITEM_LIST }
        </aside>
    )
}

export default Breadcrumbs;