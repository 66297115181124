// モジュール
import { collection, getDocs, query, limit, orderBy, where } from 'firebase/firestore';

// データ
import { BlogsConverter } from '../../../data/firebase/blog/Blogs';
import { BlogData } from '../../../data/common/BlogData';
import { STATUS_CODE } from '../../../data/common/StatusCode';


export const getBlogs = async (
        firestore   : any,
        setBlogs    : any,
        limitV      : number = 0,
        categoryV   : string = '',
        userId      : string = '',
    ) => {
    const CLEECTION_BLOG = collection(firestore, BlogData.BLOGS);
    const CONVERTER = BlogsConverter;
    const CREATED_AT_DESC = orderBy('created_at', 'desc');
    
    let blogQuery;                                  // 複合query
    let ownerFlg = false;                           // オーナー画面フラグ
    let inStatus = STATUS_CODE.error.notAcceptable; // ステータス 初期値は失敗用

    // オプションあり用query
    const optionQuery = (option : any) => {
        return query(
            CLEECTION_BLOG,
            CREATED_AT_DESC,
            option
        ).withConverter(CONVERTER);
    }

    // オプションとlimitあり用query
    const optionAddLimitQuery = (option : any, limit : any) => {
        return query(
            CLEECTION_BLOG,
            CREATED_AT_DESC,
            option,
            limit
        ).withConverter(CONVERTER);
    }

    // オプションあり
    if(categoryV !== ''){
        // カテゴリあり
        if(limitV > 0){
            // かつlimitあり
            blogQuery = optionAddLimitQuery(
                where('category', '==', categoryV),
                limit(limitV)
            );
        }else{
            // カテゴリのみ
            blogQuery = optionQuery(where('category', '==', categoryV));
        }
    }else if(userId !== ''){
        // userIdあり
        blogQuery = optionQuery(where('userId', '==', userId));
        ownerFlg = true;
    }else if(limitV > 0){
        // limitあり
        blogQuery = optionQuery(limit(limitV));
    }else{
        // 標準
        blogQuery = query(
            CLEECTION_BLOG,
            CREATED_AT_DESC,
        ).withConverter(CONVERTER);
    }

    // ブログ取得
    await getDocs(blogQuery)
        .then((snapShoto) => {
            let inData : any = [];      // 取得データ

            // データ取得
            const Data   = snapShoto.docs.map((doc) => (
                {
                    ownerFlg : ownerFlg,
                    ...doc.data(),
                }
            ));

            // データがある場合
            if(Data.length > 0){
                inStatus = STATUS_CODE.success.ok;
                inData   = Data;
            }

            setBlogs({
                status  : inStatus,
                data    : inData,
            });
        })
        .catch((err) => {
            setBlogs({
                status  : STATUS_CODE.error.badRequest,
            });
        });
}