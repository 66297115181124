// モジュール
import React, { useEffect, useState } from 'react';

function FormSelect({props} : any){
    const { label, getMethod, judgeMethod, defaultVal, list, getOldMethod, oldValue = '' } = props;

    // リストからoption生成
    const categoryOptions = list.map((val : any) => {
        return(
            <option key={ val.value } value={ val.value }>
                { val.text }
            </option>
        )
    });

    const [isSelect, setIsSelect] = useState(true);     // 選択の確認用
    const [errMessage, setErrMessage] = useState('');   // エラーメッセージ用
    const [value, setValue] = useState(oldValue);       // 選択値

    // 選択有無を判定
    const selectCheck = (value: string, defaultVal : string) => {
        const res = value === defaultVal; // 選択有無を判定

        const selectMessage = res ? '選択してください。' : '';

        setErrMessage(selectMessage);

        judgeMethod(res);

        setIsSelect(res);
    };
    
    // 値を取得
    const getValue = (value : string) => {
        selectCheck(value, defaultVal); // 選択有無を判定
        
        setValue(value);                // 選択値セット

        getMethod(value);               // 値を渡す
    };

    useEffect(() => {
        if(oldValue !== ''){
            setValue(oldValue);         // 初期値セット

            const res = oldValue === defaultVal;
    
            if(getOldMethod){
                getOldMethod(oldValue);   // 古いデータセット
                setIsSelect(res);
            }
    
            getMethod(oldValue);          // 初期値設定
            judgeMethod(res);
        }
    }, [oldValue, defaultVal, getOldMethod, getMethod, judgeMethod]);

    return(
        <section className='form_label select'>
            <label>{ label }</label>
            <select
                onChange={ e => { getValue(e.target.value) } }
                className={ `form_select ${ isSelect ? 'init' : '' }` }
                value={ value }
                >
                { categoryOptions }
            </select>
            <p className={ `select_default ${ isSelect ? '' : 'hidden' }` }>
                {
                    list.map((item : any) => {
                        if(item.value === defaultVal){
                            return item.text + ' (必須)';
                        }
                    })
                }
            </p>
            <p className='formError'>{ errMessage }</p>
        </section>
    )
}

export default FormSelect;
