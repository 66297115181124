import React from 'react';
import { fbApp } from '../../firebase';
import { format } from '../../method/common/format';

function StructuredDataJson({ props } : any){
    const {
        logoFlg     = false,
        breadcrumbs = [],
        article     = {},
        websiteFlg  = false,
    } = props;

    const BREADCRUMB_DATA   = breadcrumbs;              // パンくずデータ
    const LOGO_FLG          = logoFlg;                  // logo表示
    const ARTICLE_DATA      = article;                  // 記事データ
    const WEBSITE_FLG       = websiteFlg;               // サイト名表示
    const SITE_URL          = 'https://kacky-blog.com'; // サイトURL
    const AUTHOR_NAME       = 'kacky';                  // 著者
    const SITE_NAME         = 'kacky-blog';             // サイト名
    const STRUCRURED_ARRAY : Array<object>  = [];       // 構造化データを格納
    
    // パンくずの構造化データ処理
    if(BREADCRUMB_DATA.length > 0){
        // パンくずのアイテム
        const BREADCRUMB_ITEMS = [
            { 'name' : SITE_NAME, 'link' : SITE_URL},
            ...BREADCRUMB_DATA
        ];
        
        // パンくずのアイテムを構造化データ型式に整形
        const BREADCRUMB_ITEM_LIST = BREADCRUMB_ITEMS.map((list : any, index : number) => {
            const positionIndex = index + 1;            // 1からスタート
            
            let itemUrl = list.link;                    // アイテムにセットするURL
            if(BREADCRUMB_ITEMS.length - 1 <= index){   // 最後のアイテムはURLなし
                itemUrl = '';
            }else if(index > 0){                        // 2階層以降ドメインに追加
                itemUrl = SITE_URL + list.link;
            }
    
            return(
                {
                    '@type'   : 'ListItem',
                    'position': positionIndex,
                    'name'    : list.name,
                    'item'    : itemUrl
                }
            )
        });
    
        // パンくず用構造化データ型式に整形
        const BREADCRUMB = {
            '@context'          : 'https://schema.org',
            '@type'             : 'BreadcrumbList',
            'itemListElement'   : [BREADCRUMB_ITEM_LIST]
        }
    
        // 構造化データ配列にセット
        STRUCRURED_ARRAY.push(BREADCRUMB);
    }
    
    // logoの構造化データ処理
    if(LOGO_FLG){
        // 構造化データ型式に整形
        const LOGO = {
            '@context'  : 'https://schema.org',
            '@type'     : 'Organization',
            'url'       : SITE_URL,
            'logo'      : SITE_URL + '/logo50.PNG'
        }
    
        // 構造化データ配列にセット
        STRUCRURED_ARRAY.push(LOGO);
    }
    
    // websiteの構造化データ処理
    if(WEBSITE_FLG){
        // 構造化データ型式に整形
        const WEBSITE = {
            '@context'  : 'https://schema.org',
            '@type'     : 'WebSite',
            'name'      : SITE_NAME,
            'url'       : SITE_URL
        }
    
        // 構造化データ配列にセット
        STRUCRURED_ARRAY.push(WEBSITE);
    }

    // Article構造化データ処理
    if(Object.keys(ARTICLE_DATA).length !== 0){
        // imageセット
        let imageArray : any = [];
        if(ARTICLE_DATA.images && ARTICLE_DATA.images.length > 0){
            ARTICLE_DATA.images.forEach((item : any) => {
                if(!item.flg){
                    return;
                }
    
                const url = format.setSrc(fbApp.userId, ARTICLE_DATA.fbColection, ARTICLE_DATA.id, item.filenmae);
                imageArray.push(url);
            });
        }

        // パンくず用構造化データ型式に整形
        const ARTICLE = {
            '@context'  : 'https://schema.org',
            '@type'     : 'Article',
            'headline'  : ARTICLE_DATA.headline,
            'author'    : {
                '@type' : 'Person',
                'name'  : AUTHOR_NAME,
                'url'   : SITE_URL + '/profile',
            },
            'image'             : imageArray,
            'datePublished'     : ARTICLE_DATA.datePublished,
            'dateModified'      : ARTICLE_DATA.dateModified,
            'mainEntityOfPage'  : SITE_URL + ARTICLE_DATA.mainEntityOfPage,
        }

        // 構造化データ配列にセット
        STRUCRURED_ARRAY.push(ARTICLE);
    }

    // 構造化データがない場合はjson化処理をしない
    if(STRUCRURED_ARRAY.length <= 0){
        return <></>;
    }
    
    // 構造化データをJSON形式でscriptに整形
    const STRUCRURED_JSON = STRUCRURED_ARRAY.map((list, index) => {
        return (
            <script type='application/ld+json' key={ index }>
                { JSON.stringify(list) } 
            </script>
        )
    });

    return(
        <>
            { STRUCRURED_JSON }
        </>
    )
}

export default StructuredDataJson;