// モジュール
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

type FireAuthContextProviderProps = {
    children : ReactNode
}

type FireAuthContextProps = {
    user : any,
}

// FireAuthContextの初期化
export const FireAuthContext = createContext<FireAuthContextProps>({user : null});

// ユーザ認証状態確認コンテキスト
export function useAuthContext(){
    return useContext(FireAuthContext);
}

// ログアウト処理
export function logout(navigate : (val : string) => void){
    const auth = getAuth();

    signOut(auth)
    .then(() => {
        return navigate('/login');               // ログアウト成功
    })
    .catch((error) => {
        return navigate('/error/logout');   // ログアウト失敗
    });
}

// FireAuthContextのプロバイダ
export const FireAuthContextProvider = ({children} : FireAuthContextProviderProps) => {
    const auth = getAuth();                         // firebase認証

    const [user, setUser] = useState<any>('');      // 認証したユーザーを格納
    const [loading, setLoading] = useState(false);  // 認証成功状態を確認中フラグ
    
    const value = {
        user : user
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {    // 認証状態を取得
            setUser(user);
            setLoading(true);
        });
        
        return () => { unsubscribe() };                             // 認証確認を一度のみ実行
    }, [auth]);

    return(
        <FireAuthContext.Provider value={ value }>
            { loading && children }
        </FireAuthContext.Provider>
    )
}

export default FireAuthContextProvider;