// モジュール
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { logEvent } from "firebase/analytics";

// データ
import { FooterLinkData, FooterSnsData } from '../../../data/common/FooterData';  // フッターリンクリスト
import { scrollView } from '../../../method/common/scrollView';                            // スクロールでコンテンツ表示
import { fbApp } from '../../../firebase';

// スタイル
import '../../../style/common/footer.css';

function Footer(){
    const analytics = fbApp.analytics;

    const footerListSelector = 'footer_menu_list'; // スクロール用セレクタ

    const footerMenuList = FooterLinkData.map((list) => {
        return(
            <li
                className={`${footerListSelector} hidden`}
                key={ list.link }
                onClick={ () => {
                    logEvent(analytics, 'search', {
                        content_type    : 'link',
                        content_id      : list.link,
                        items : [{name : list.name}]
                    })
                } }
            >
                <Link to={ list.link }>{ list.name }</Link>
            </li>
        )
    });

    const footersnsList = FooterSnsData.map((list, key) => {
        return(
            <li className='footer_sns_list' key={ key }>
                <a href={ list.link }>
                    <img src={ list.icon } alt={ list.alt } loading="lazy" />
                </a>
            </li>
        )
    });

    useEffect(() => {
        scrollView(footerListSelector); // スクロールで表示イベント
    });

    return(
        <footer className='Footer'>
            <section>
                <nav>
                    <ul>
                        { footerMenuList }
                    </ul>
                </nav>
            </section>

            <section>
                <nav>
                    <ul className='footer_sns_lists'>
                        { footersnsList }
                    </ul>
                </nav>
            </section>
        </footer>
    )
}

export default Footer;