// モジュール
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

// コンポーネント
import BlogCassette from './BlogCassette';
import Breadcrumbs from '../common/Breadcrumbs';
import TopScrollView from '../common/TopScrollView';
import Modal from '../common/Modal';
import StructuredDataJson from '../seo/StructuredDataJson';
import ErrorPages from '../common/ErrorPages';
import Loading from '../common/Loading';

// メソッド
import { metaChange } from '../../method/common/metaChange';
import { getBlogs } from '../../method/firebase/blog/getBlogs';
import { getBlogCounts } from '../../method/firebase/blog/getBlogCounts';

// データ
import { BlogsCount } from '../../data/firebase/blog/BlogsCount';
import { CategoryList, BlogLinkData } from '../../data/common/BlogData';
import { fbApp } from '../../firebase';
import { STATUS_CODE } from '../../data/common/StatusCode';

// スタイル
import '../../style/blog/blogList.css';

function BlogList(){
    let pageTitle                           = 'BLOG LIST';                                          // ページタイトル
    let description                         = '全てのブログを一覧にしてます。是非、他の記事もご一読ください。';// description
    let breadcrumbsFirst                    = { name : '', link : ''};                              // パンくず用変数
    let viewTemplate                        = <></>                                                 // 表示テンプレート
    let searchBanner                        = <></>;                                                // バナーテンプレート
    const FIRESTORE                         = fbApp.firestore;                                      // firestore
    const BLOGLIST_LINK                     = BlogLinkData.LIST.link;                               // ブログ一覧のリンク
    const BREADCRUMB_ARRAY                  = [{ name : pageTitle, link: BLOGLIST_LINK }];          // パンくずの値配列を初期化
    const { category }                      = useParams();                                          // カテゴリのパラメータ
    const location                          = useLocation();                                        // ロケーション
    const [blogs, setBlogs]                 = useState({ status : '', data : [] });                 // ブログデータ
    const [blogsCount, setBlogsCount]       = useState<BlogsCount[]>([]);                           // ブログカウント
    const [modalViewFlg, setModalViewFlg]   = useState(false);                                      // モーダル表示制御

    useEffect(() => {
        // ブログ取得
        if(category){
            getBlogs(FIRESTORE, setBlogs, 0, category);
        }else{
            getBlogs(FIRESTORE, setBlogs, 0);
        }

        //ブログカウント取得
        getBlogCounts(FIRESTORE, setBlogsCount);
    }, [
        category,
        FIRESTORE,
        location,
    ]);

    if(category){
        // カテゴリ名セット
        CategoryList.forEach((list) => {
            if(list.value === category){
                pageTitle   = `BLOG ${list.name}`;
                description = `ブログのカテゴリ（${ list.name }）の一覧です。是非、ご一読ください。`;
            };
        });

        // パンくずの第一階層にカテゴリの親階層セット
        breadcrumbsFirst.name = BlogLinkData.LIST.name;
        breadcrumbsFirst.link = BLOGLIST_LINK;

        // カテゴリのパンくずセット
        BREADCRUMB_ARRAY.push({ name : pageTitle, link: BLOGLIST_LINK + '/' + category });
    };

    // ブログカウントが取得できなければバナーを非表示
    if(blogsCount.length > 0){
        // モーダル表示イベント
        const clickBanner = () => {
            setModalViewFlg(!modalViewFlg)
        };
        
        blogsCount.forEach((data : any) => {
            CategoryList.forEach((list : any) => {
                if(data.category === list.value){
                    list.count = data.count;
                };
            });
        });
        
        const modalProps = {
            title       : 'CATEGORY',
            parent      : BLOGLIST_LINK,
            lists       : CategoryList,
            viewFlg     : modalViewFlg,
            setViewFlg  : setModalViewFlg,
            switching   : 'blog_category',
        };
    
        searchBanner = (
            <section className='blog_search_banner'>
                <div className='SearchBanner'>
                    <p onClick={ () => { clickBanner() } }>Category</p>
                </div>
                <Modal props={ modalProps } />
            </section>
        );
    }

    // 画面表示
    if(blogs.status === ''){
        // 初期
        viewTemplate = <Loading />;
    }else if(
            blogs.status === STATUS_CODE.error.notAcceptable
            || blogs.status === STATUS_CODE.error.badRequest
        ){
        // 取得エラー
        viewTemplate = <ErrorPages props={ '' } />;
    }else{
        viewTemplate = (
            <>
                {/* ぱんくず */}
                <Breadcrumbs props={{
                    data : BREADCRUMB_ARRAY,
                }} />

                {/* メインコンテンツ */}
                <main className='BlogList'>
                    <h1>{ pageTitle }</h1>
                    
                    <section className='blog_lost_contents'>
                        <section className='list'>
                            <ul className='blog_cassettes'>
                                {
                                    blogs.data.map((blog: any) => {
                                        return(
                                            <BlogCassette props={ blog } key={ blog.blogId } />
                                        )
                                    })
                                }
                            </ul>
                        </section>

                        {/* 検索ボタン */}
                        { searchBanner }
                    </section>
                </main>

                {/* ぱんくず */}
                <Breadcrumbs props={{
                    data : BREADCRUMB_ARRAY,
                }} />

                {/* トップページボタン */}
                <TopScrollView />

                {/* 構造化データ */}
                <StructuredDataJson props={{
                    breadcrumbs : BREADCRUMB_ARRAY
                }} />
            </>
        )

        // metaタグセット
        metaChange({
            title       : pageTitle,
            description : description,
            url         : window.location.href,
        });
    }

    return(
        <section className='Contents_Container'>
            { viewTemplate }
        </section>
    )
}

export default BlogList;