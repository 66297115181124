// モジュール
import React, { useState } from 'react';

// コンポーネント
import Breadcrumbs from '../common/Breadcrumbs';
import TopScrollView from '../common/TopScrollView';
import Btns from '../common/Btns';
import StructuredDataJson from '../seo/StructuredDataJson';

// メソッド
import { metaChange } from '../../method/common/metaChange';

// スタイル
import '../../style/info/common.css';

function Disclaimer(){
    const pageTitle         = 'DISCLAIMER';                                             // 画面タイトル
    const description       = process.env.REACT_APP_SITE_NAME + 'の免責事項について';      // 画面説明 
    const [breadcrumbsData] = useState([{ name : pageTitle, link : '/disclaimer' }]);   // パンくずデータ

    // metaタグセット
    metaChange({
        title       : pageTitle,
        description : description,
        url         : window.location.href,
    });

    return(
        <section className='Contents_Container'>
            {/* ぱんくず */}
            <Breadcrumbs props={{
                data : breadcrumbsData,
            }} />
            
            <main className='info'>
                <h1>{ pageTitle }</h1>

                <section className='list'>
                    <h2>損害等の責任について</h2>
                    <p>当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。</p>
                    <p>当サイトからのリンクやバナーなどで移動したサイトで提供される情報、サービス等について一切の責任を負いません。</p>
                    <p>各コンテンツでは、できる限り正確な情報提供を心がけておりますが、正確性や安全性を保証するものではありません。</p>
                    <p>当サイトの保守、火災、停電、その他の自然災害、ウィルスや第三者の妨害等行為による不可抗力によって、当サイトによるサービスが停止したことに起因して利用者に生じた損害についても、何ら責任を負うものではありません。</p>
                    <p>当サイトを利用する場合は、自己責任で行う必要がございます。</p>
                </section>

                <section className='list'>
                    <h2>著作権について</h2>
                    <p>当サイトで掲載している文章や画像などにつきましては、無断転載することを禁止いたします。</p>
                    <p>当サイトは著作権や肖像権の侵害を目的としたものではありません。著作権や肖像権に関して問題がございましたら、お問い合わせフォームよりご連絡ください。迅速に対応いたします。</p>
                    <p></p>
                </section>

                <section className='list'>
                    <h2>リンクについて</h2>
                    <p>当サイトは基本的にリンクフリーであり、リンクを貼る際の許可やご連絡は不要です。</p>
                    <p>ただし、インラインフレームの使用や画像の直リンクはご遠慮ください。</p>
                </section>

                <section className='enactment'>
                    <h6>制定日</h6>
                    <p>2022/6/28</p>
                </section>
                
                <Btns props={ '' } />
            </main>

            {/* ぱんくず */}
            <Breadcrumbs props={{
                data : breadcrumbsData,
            }} />

            {/* トップスクロールボタン */}
            <TopScrollView />

            {/* 構造化データ */}
            <StructuredDataJson props={{
                breadcrumbs : breadcrumbsData
            }} />
        </section>
    )
}

export default Disclaimer;