// モジュール
import { collection, getDocs, query, limit, orderBy } from 'firebase/firestore';

// コンバーター
import { MaxBlogIdConverter } from '../../../data/firebase/blog/MaxBlogId';

export const getMaxBlogId = (
        firestore   : any,
        colleciton  : any,
        setMaxId    : any,
    ) => {
    const IS_CLEECTION = collection(firestore, colleciton);
    const BLOG_ID_DESC = orderBy('blogId', 'desc');

    const MAX_ID_QUERY = query(
        IS_CLEECTION,
        BLOG_ID_DESC,
        limit(1)
    ).withConverter(MaxBlogIdConverter);

    // ブログ取得
    getDocs(MAX_ID_QUERY)
        .then((snapShoto) => {
            // 最大id初期化
            let maxId : number = 0;

            // ドキュメントがある場合
            if(!snapShoto.empty){
                maxId = snapShoto.docs[0].data().blogId;
            }

            setMaxId(maxId);
        })
        .catch((err) => {
            setMaxId(-1);
        });
}