// モジュール
import React from 'react';
import { Link } from 'react-router-dom';

// データ
import { ButtonList } from '../../data/common/ButtonData';
import { LinkMaster } from '../../data/LinkMaster';

// スタイル
import '../../style/common/btns.css';

function Btns({props} : any){
    // デフォルトをHOME用に設定
    let btnLink   : string = ButtonList.HOME.link;
    let btnName   : string = ButtonList.HOME.name;
    let backColor : string = '#008080';

    switch(props){
        case 'CONTACT' : // お問い合わせ
            btnLink   = ButtonList.CONTACT.link;
            btnName   = ButtonList.CONTACT.name;
            backColor = '#000080';
            break;
        case 'OWNER' : // オーナー画面
            btnLink   = ButtonList.OWNER.link;
            btnName   = ButtonList.OWNER.name;
            backColor = '#008080';
            break;
        case 'BLOG_INPUT' : // オーナーブログ投稿画面
            btnLink   = ButtonList.BLOG_INPUT.link;
            btnName   = ButtonList.BLOG_INPUT.name;
            backColor = '#008080';
            break;
        case 'BLOG_LIST' : // ブログ一覧画面
            btnLink   = ButtonList.BLOG_LIST.link;
            btnName   = ButtonList.BLOG_LIST.name;
            backColor = '#000080';
            break;
        case 'BLOGS' : // オーナーブログ一覧画面
            btnLink   = ButtonList.BLOGS.link;
            btnName   = ButtonList.BLOGS.name;
            backColor = '#008080';
            break;
        case 'CONTACTS' : // オーナー問い合わせ一覧画面
            btnLink   = ButtonList.CONTACTS.link;
            btnName   = ButtonList.CONTACTS.name;
            backColor = '#008080';
            break;
        case 'LOGOUT' : // ログアウト
            btnName   = 'LOGOUT';
            backColor = 'red';
            break;
        case 'HELP' : // お手伝い画面
            btnLink   = LinkMaster.HLEP.link;
            btnName   = LinkMaster.HLEP.name;
            backColor = '#008080';
            break;
    }

    return(
        <section>
            <Link
                to={ btnLink }
                className='Btns'
                style={{
                    backgroundColor : backColor
                }}
            >
                <p>{ btnName }</p>
            </Link>
        </section>
    )
}

export default Btns;