//データ
import { LinkMaster } from '../LinkMaster';

// ヘッダーサイトロゴ
export const HeaderHomeLink = LinkMaster.HOME;

// ヘッダーメニューリンクリスト
export const HeaderLinkData = [
    LinkMaster.HOME_ICON,
    LinkMaster.BLOG_LIST,
    LinkMaster.PROFILE,
    LinkMaster.CONTACT,
];