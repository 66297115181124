// メソッド
import { format } from './format';

export const metaChange = (props : any) => {
    const {title, description, url, noIndexFlg = false } = props;

    // セットするタグ取得
    const metaSet = (target : string, value : string) => {
        const element = document.querySelector(target);
        if(element) element.setAttribute('content', value);
    };

    const metaDelete = (target : string) => {
        const element = document.querySelector(target);
        if(element){
            element.remove();
        }
    };

    // スクリーン幅取得
    const windowW = window.innerWidth;

    // descriptionの設定値
    let formatDesc = description;

    // モバイル用descriptionにフォーマット
    if(windowW < 1000 && description){
        formatDesc = format.textCut(description, 50);
    };

    // title設定
    document.title = title;

    // description設定
    if(description !== ''){
        metaSet('meta[name="description"]', formatDesc);
    }

    // og設定
    if(url !== undefined){
        metaSet('meta[property="og:title"]', title);
        metaSet('meta[property="og:description"]', formatDesc);
        metaSet('meta[property="og:url"]', url);
    }else{
        metaDelete('meta[property="og:title"]');
        metaDelete('meta[property="og:description"]');
        metaDelete('meta[property="og:url"]');
        metaDelete('meta[property="og:site_name"]');
        metaDelete('meta[property="og:image"]');
        metaDelete('meta[property="og:type"]');
        metaDelete('meta[property="og:locale"]');
    };

    // robots設定
    const ROBOTS = noIndexFlg ? 'noindex,nofollow' : 'all';
    metaSet('meta[name="robots"]', ROBOTS);
}