// モジュール
import React, { useEffect, useState } from 'react';
import { Timestamp, collection, addDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';

// コンポーネント
import Breadcrumbs from '../common/Breadcrumbs';
import TopScrollView from '../common/TopScrollView';
import FormInput from '../form/FormInput';
import FormSelect from '../form/FormSelect';
import FormText from '../form/FormText';
import SuccessComplete from '../common/SuccessComplete';
import Btns from '../common/Btns';
import StructuredDataJson from '../seo/StructuredDataJson';

// メソッド
import { metaChange } from '../../method/common/metaChange';
import { emailSend } from '../../method/form/emailSend';

// データ
import { fbApp } from '../../firebase';

// スタイル
import '../../style/form/form.css';

function Contact(){
    const pageTitle     = 'CONTACT';
    const description   = 'お問い合わせ画面';
    const nameLength    = 50;
    const emailLength   = 191;
    const subjetList    = [
        {name : 'SELECT'    , value : 'select'  , text : '選択してください'},
        {name : 'BLOG'      , value : 'blog'    , text : 'ブログ'},
        {name : 'QUESTION'  , value : 'question', text : 'ご質問'},
        {name : 'REQUEST'   , value : 'request' , text : 'ご依頼'},
        {name : 'OTHERS'    , value : 'others'  , text : 'その他'},
    ];
    const subjetDfault  = subjetList[0].value;
    const textLength    = 140;
    const [breadcrumbsData] = useState([{ name : pageTitle, link : '/contact' }]); // パンくずデータ

    // 入力フォーム値・エラー判定
    // name
    const [name, setName] = useState('');
    const [nameFlg, setNameFlg] = useState(true);
    // email
    const [email, setEmail] = useState('');
    const [emailFlg, setEmailFlg] = useState(true);
    // subject
    const [subject, setSubject] = useState(subjetDfault);
    const [subjectFlg, setSubjectFlg] = useState(true);
    // text
    const [text, setText] = useState('');
    const [textFlg, setTextFlg] = useState(true);
    // ip
    const [ip, setIp] = useState('');
    // 送信完了フラグ
    const [seccessFlg, setSuccessFlg] = useState(false);

    // リダイレクト
    const navi = useNavigate();

    // ボタン制御
    const disabledFlg = nameFlg || emailFlg || subjectFlg || textFlg;

    const handleSubmit = (event : any) => {
        event.preventDefault();

        if(disabledFlg){ // trueなら以後処理なし
            return;
        };

        const date = Timestamp.fromDate(new Date());

        try {
            // ブログ登録データ
            const data = {
                userId      : fbApp.userId,
                name        : name,
                email       : email,
                subject     : subject,
                userText    : text,
                ip          : ip,
                ownerText   : '',
                created_at  : date,
                update_at   : date,
            };

            // ブログ登録処理
            const contactsRef = collection(fbApp.firestore, 'contacts');
            addDoc(contactsRef, data)
            .then(() => {
                // 送信完了メッセージ表示
                setSuccessFlg(true);

                // メール送信
                const emailParams = {
                    fromName    : name,
                    fromEmail   : email,
                    fromSubject : subject,
                    fromText    : text,
                    siteName    : process.env.REACT_APP_SITE_NAME,
                    siteEmail   : process.env.REACT_APP_INFO_MAIL_ADDRESS,
                };

                emailSend('CONTACT', emailParams);
            })
            .catch(() => {
                return navi('/error/connection');   // 接続エラー画面へリダイレクト
            });
        } catch(e) {
            return navi('/error/connection');       // 接続エラー画面へリダイレクト
        };
    };

    // ip取得
    async function getIp(){
        const API_URL = 'https://api.ipify.org/?format=json';
        const res = await fetch(API_URL);
        const data = await res.json();
        setIp(data.ip);
    }

    useEffect(() => {
        // IP取得
        getIp();
    });

    const viewPage = !seccessFlg ? (
        <main>
            <h1 className='form_title' style={{ marginTop : '30px' }}>{ pageTitle }</h1>

            <form onSubmit={ handleSubmit } >
                <FormInput props={{
                    label          : 'Name',
                    type           : 'text',
                    getMethod      : setName,
                    judgeMethod    : setNameFlg,
                    length         : nameLength,
                    placeholderFlg : true,
                    requiredFlg    : true,
                }} />

                <FormInput props={{
                    label          : 'Email',
                    type           : 'email',
                    getMethod      : setEmail,
                    judgeMethod    : setEmailFlg,
                    length         : emailLength,
                    placeholderFlg : true,
                    requiredFlg    : true,
                }} />

                <FormSelect props={{
                    label          : 'Subject',
                    list           : subjetList,
                    defaultVal     : subjetDfault,
                    getMethod      : setSubject,
                    judgeMethod    : setSubjectFlg,
                }} />

                <FormText props={{
                    label          : 'Text',
                    getMethod      : setText,
                    judgeMethod    : setTextFlg,
                    length         : textLength,
                    requiredFlg    : true,
                }} />

                <button
                    type='submit'
                    disabled={ disabledFlg }
                >SEND</button>
            </form>

            <Btns props={ '' } />
        </main>
    ) : <SuccessComplete props={ '/' } /> ;

    // metaタグセット
    metaChange({
        title       : pageTitle,
        description : description,
        url         : window.location.href,
    });

    return(
        <section className='Contents_Container'>
            {/* ぱんくず */}
            <Breadcrumbs props={{
                data : breadcrumbsData,
            }} />

            {/* コンテンツ */}
            { viewPage }

            {/* ぱんくず */}
            <Breadcrumbs props={{
                data : breadcrumbsData
            }} />

            {/* トップスクロールボタン */}
            <TopScrollView />

            {/* 構造化データ */}
            <StructuredDataJson props={{
                breadcrumbs : breadcrumbsData
            }} />
        </section>
    )
}

export default Contact;