// モジュール
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// コンポーネント
import InCarousel from '../common/InCarousel';

// メソッド
import { format } from '../../method/common/format';
import { getCategoryBlogs } from '../../method/firebase/blog/getCategoryBlogs';

// データ
import { BlogLinkData } from '../../data/common/BlogData';
import { ImageData } from '../../data/common/ImageData';
import { CategoryBlogs } from '../../data/firebase/blog/CategoryBlogs';

// スタイル
import '../../style/include/InCategoryBlogCasstte.css';

function InCategoryBlogCassette({ props } : any){
    const { firestore, category, blogId } = props;

    const [categoryBlogs, setCategoryBlogs] = useState<CategoryBlogs[]>([]); // ブログカテゴリデータ
    const [categoryBlogsFlg, setCategoryBlogsFlg] = useState(false);         // ブログカテゴリデータ取得フラグ

    // 最大表示数
    const MAX_LENGTH = 3;

    // カセットDOM生成
    const CASSETTES = categoryBlogs.map((cassette : any, index : number) => {
        return(
            <Link
                key={ index }
                to={ format.createLink(BlogLinkData.DETAIL.link, cassette.blogId) }
                className='In_Category_Blog_Cassette'
            >
                <h3 className='in_blog_title'>{ cassette.title }</h3>

                <div className='in_blog_attached'>
                    <img
                        src={ ImageData.Date30.src }
                        className='in_blog_date_icon'
                        loading='lazy'
                        alt='calender_icon'
                    />
                    <p className='in_blog_date'>{ cassette.date }</p>
                </div>
                <p className='in_blog_desc'>{ cassette.description }</p>
            </Link> 
        )
    });

    useEffect(() => {
        // ブログ取得
        getCategoryBlogs(firestore, setCategoryBlogs, setCategoryBlogsFlg, MAX_LENGTH, category, blogId);
    }, [
        firestore,
        category,
        blogId
    ]);

    // データがなければ表示なし
    if(!categoryBlogsFlg){
        return <></>;
    }

    return(
        <section>
            <h3 style={{
                    padding: '0 20px'
            }}>同じカテゴリのブログ</h3>

            <InCarousel props={{
                dataList        : CASSETTES,
                containerClass  : 'In_Category_Blog_Cassette_Container',
            }}/>
        </section>
    )
}

export default InCategoryBlogCassette;