// モジュール
import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

// コンテキスト
import { useAuthContext, logout } from '../context/auth/FireAuthContext';

function PrivateRoute({ element : Component} : any){
    const navigate = useNavigate();                 // リダイレクト

    const { user } = useAuthContext();

    useEffect(() => {
        // ログイン経過時間判定とログアウト
        if(user){
            const lastLogin = user.metadata.lastSignInTime;                // 最終ログイン時間取得
            if(lastLogin){
                const TIME_LIMIT        = 2;                               // 2時間を設定
                const TIME_LIMIT_INT    = TIME_LIMIT * (60 * 60 * 1000);   // ミリ秒単位に数値変換
                const NOW               = new Date().getTime();            // 現在時刻の数値
                const LAST              = new Date(lastLogin).getTime();   // 最終ログイン時刻の数値
                const DIFF              = NOW - LAST;                      // 現在時刻と最終ログイン時刻の差分
                const COMPARE           = DIFF > TIME_LIMIT_INT;           // 指定時間比較

                if(COMPARE){
                    logout(navigate);
                };
            };
        };
    }, [user, navigate]);
    
    const view = user ? Component : <Navigate to='/login' />;

    return(
        view
    );
}

export default PrivateRoute;