import { FirestoreDataConverter } from 'firebase/firestore';
import { format } from '../../../method/common/format';

// 型定義
export type Blogs = {
    blogId      : number;
    userId      : string;
    createdDate : string;
    title       : string;
    imageFlg    : boolean;
    description : string;
    category    : string;
};

// Firestore用値変換
export const BlogsConverter : FirestoreDataConverter<Blogs> = {
    // Firestoreへ送信用
    toFirestore: (blog) => {
        return {
            blogId      : blog.blogId,
            userId      : blog.userId,
            title       : blog.title,
            description : blog.description,
            category    : blog.category,
            imageFlg    : blog.imageFlg,
        };
    },

    // Firestoreから取得用
    fromFirestore : (snapshot)=> {
        const BLOG = snapshot.data();

        return {
            blogId      : BLOG.blogId,
            userId      : BLOG.userId,
            createdDate : format.jaDate(BLOG.created_at),
            title       : BLOG.title,
            description : BLOG.description,
            category    : BLOG.category,
            imageFlg    : BLOG.imageFlg,
        }
    },
};