// モジュール
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// コンテキスト
import FireAuthContextProvider from './components/context/auth/FireAuthContext';

// コンポーネント
import Header from './components/common/header/Header';
import Footer from './components/common/footer/Footer';
import Top from './components/Top';
import ErrorPages from './components/common/ErrorPages';
import AccessPageTop from './components/common/AccessPageTop';
import PrivateRoute from './components/auth/PrivateRoute';
import BlogList from './components/blog/BlogList';
import BlogDetail from './components/blog/BlogDetail';
import Profile from './components/info/Profile';
import Contact from './components/info/Contact';
import PrivacyPolicy from './components/info/PrivacyPolicy';
import Disclaimer from './components/info/Disclaimer';
import Login from './components/auth/Login';
import Owner from './components/owner/Owner';
import BlogInput from './components/owner/blog/BlogInput';
import BlogEdit from './components/owner/blog/BlogEdit';
import OwnerBlogs from './components/owner/blog/OwnerBlogs';
import OwnerContacts from './components/owner/info/OwnerContacts';
import OwnerContactReply from './components/owner/info/OwnerContactReply';
import Help from './components/owner/help/Help';

// スタイル
import './style/App.css';

function App(){
    return(
        <div className='App'>
            <FireAuthContextProvider>
                <BrowserRouter>
                    <AccessPageTop />
                    <Header />

                    <Routes>
                        {/* 公開系 */}
                        <Route path='/' element={
                            <Top />
                        } />
                        <Route path='/blog_list' element={
                            <BlogList /> 
                        } />
                        <Route path='/blog_list/:category' element={
                            <BlogList />
                        } />
                        <Route path='/blog_detail/:blogId' element={
                            <BlogDetail />
                        } />
                        <Route path='/profile' element={
                            <Profile />
                        } />
                        <Route path='/contact' element={
                            <Contact />
                        } />
                        <Route path='/privacy_policy' element={
                            <PrivacyPolicy />
                        } />
                        <Route path='/disclaimer' element={
                            <Disclaimer />
                        } />
                        <Route path='/login' element={
                            <Login />
                        } />

                        {/* 認証系 */}
                        <Route path='/owner' element={ <PrivateRoute element={ <Owner /> } /> } />
                        <Route path='/owner/blog/input' element={ <PrivateRoute element={ <BlogInput /> } /> } />
                        <Route path='/owner/blog/edit/:blogId' element={ <PrivateRoute element={ <BlogInput /> } /> } />
                        {/* <Route path='/owner/blog/edit/:blogId' element={ <PrivateRoute element={ <BlogEdit /> } /> } /> */}
                        <Route path='/owner/blogs' element={ <PrivateRoute element={ <OwnerBlogs /> } /> } />
                        <Route path='/owner/contacts' element={ <PrivateRoute element={ <OwnerContacts /> } /> } />
                        <Route path='/owner/contactReply/:contactId' element={ <PrivateRoute element={ <OwnerContactReply /> } /> } />
                        <Route path='/owner/help' element={ <PrivateRoute element={ <Help /> } /> } />

                        {/* エラー系 */}
                        <Route path='/error/connection' element={ <ErrorPages props={ 'CONNECTION' } /> } />
                        <Route path='/error/logout' element={ <ErrorPages props={ 'LOGOUT' } /> } />
                        <Route path='/error/email' element={ <ErrorPages props={ 'EMAIL' } /> } />
                        <Route path='/error/not_found' element={ <ErrorPages props={ 'NOT_FOUND' } /> } />
                        
                        {/* ルート外エラー表示 */}
                        <Route path='/*' element={ <ErrorPages props={ 'NOT_FOUND' } /> } />
                    </Routes>

                    <Footer />
                </BrowserRouter>
            </FireAuthContextProvider>
        </div>
    )
}

export default App;