// 定数
export const STATUS_CODE = {
    success : {
        ok : '200',                 // 成功
    },
    error : {
        badRequest      : '400',    // 失敗
        notFound        : '404',    // ページなし
        notAcceptable   : '406',    // データなし
    }
};