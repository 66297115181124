// モジュール
import { collection, getDocs } from 'firebase/firestore';

// データ
import { BlogsCountConverter } from '../../../data/firebase/blog/BlogsCount';
import { BlogData } from '../../../data/common/BlogData';

export const getBlogCounts = async (
        firestore   : any,
        setCounts   : any,
    ) => {
    const countQuery = collection(
            firestore,
            BlogData.COUNT
        ).withConverter(BlogsCountConverter);

    // ブログカウント取得
    await getDocs(countQuery)
        .then((snapShoto) => {
            const data = snapShoto.docs.map((doc) => (
                {
                    category : doc.id,
                    ...doc.data(),
                }
            ));

            setCounts(data);
        })
        .catch((err) => {
            setCounts([]);
        });
}