// モジュール
import React, { useEffect, useState } from 'react';

// コンポーネント
import BlogCassette from './blog/BlogCassette';
import TopScrollView from './common/TopScrollView';
import Btns from './common/Btns';
import StructuredDataJson from './seo/StructuredDataJson';

// メソッド
import { metaChange } from '../method/common/metaChange';
import { getBlogs } from '../method/firebase/blog/getBlogs';

// データ
import { ImageData } from '../data/common/ImageData';
import { fbApp } from '../firebase';
import { STATUS_CODE } from '../data/common/StatusCode';

// スタイル
import '../style/top/top.css';

function Top(){
    const title = process.env.REACT_APP_SITE_NAME;
    const description = '沖縄、観光施設、プログラミング、本の紹介など、調べたり経験した内容をアウトプットするブログです。';
    const windowHeight = window.innerHeight;
    const WINDOW_WIDTH = window.innerWidth;
    const TOP_IMAGE = WINDOW_WIDTH > 767 ? ImageData.Top1 : ImageData.Top1Sp;
    
    const [blogs, setBlogs] = useState({ status : '', data : [] });

    useEffect(() => {
        // ブログ取得
        getBlogs(fbApp.firestore, setBlogs, 9);
    }, []);

    // ブログリスト
    const blogList = blogs.status === STATUS_CODE.success.ok ? (
        <section className='contents_container'>
            <h1 className='contents_title'>BLOG</h1>

            <ul className='blog_cassettes'>
                {
                    blogs.data.map((blog: any) => {
                        return(
                            <BlogCassette props={ blog } key={ blog.blogId } />
                        )
                    })
                }
            </ul>

            <Btns props={'BLOG_LIST'} />
        </section>
    ) : '';

    // metaタグセット
    metaChange({
        title       : title,
        description : description,    
        url         : window.location.href,
    });

    return(
        <section>
            <main className='Top_main'>
                <section className="top_image" style={{ 
                    backgroundImage: `url(${ TOP_IMAGE.src })`,
                    height: `${windowHeight}px`,
                    width : `${ WINDOW_WIDTH }px`
                }}></section>

                {/* ブログリスト */}
                { blogList }
            </main>

            {/* ページトップボタン */}
            <TopScrollView />

            {/* 構造化データ */}
            <StructuredDataJson props={{
                logoFlg     : true,
                websiteFlg  : true,
            }} />
        </section>
    )
}

export default Top;