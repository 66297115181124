// モジュール
import React, { useState, useEffect } from 'react';

// コンポーネント
import nullCheck from '../../method/form/validation/nullCheck';
import maxCheck from '../../method/form/validation/maxCheck';

function FormText({props} : any){
    const { label, getMethod, judgeMethod, judgeFlg, length, requiredFlg, oldValue } = props;
    const [isNull, setIsNull] = useState(true);         // 入力の確認用
    const [errMessage, setErrMessage] = useState('');   // エラーメッセージ用

    const getValue = (value : string) => {
        judgeMethod(false);   // nullでも通過
        const res = value === '';   // 入力有無を判定
        
        setIsNull(res);             // 入力の有無結果を反映

        //文字数チェック
        const maxRes = maxCheck(
            value,
            length,
            judgeMethod,
            setErrMessage,
        );

        if(maxRes){                 // 文字数超過の場合、以降処理なし
            return;
        }

        // 必須項目の場合、nullチェック
        if(requiredFlg){      // 判定を上書き
            nullCheck(              // null判定
            res,
            judgeMethod,
            setErrMessage
            );
        }
        
        getMethod(value);    // 値を渡す
    };

    // placeholderのテキスト制御
    let phText = `最大${ length }文字`;
    if(requiredFlg){
        phText = phText + ' (必須)';
    }

    useEffect(() => {
        if(oldValue){
            getMethod(oldValue);    // 初期値設定
    
            const res = oldValue === '';
            setIsNull(res);
            
            // 初期値がtrue(必須項目)の場合のみ判定をセット
            if(judgeFlg){
                judgeMethod(res);
            }
        }
    }, [oldValue, getMethod, judgeMethod, judgeFlg]);

    return(
        <section className='form_label'>
            <label>{ label }</label>
            <textarea
                maxLength={ length }
                placeholder={ phText }
                onChange={ e => { getValue(e.target.value) } }
                className={ `form_text ${ isNull ? 'init' : '' }` }
                required={ requiredFlg }
                defaultValue={ oldValue }
            >

            </textarea>
            <p className='formError'>{ errMessage }</p>
        </section>
    )
}

export default FormText;