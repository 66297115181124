// モジュール
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// コンポーネント
import Btns from './Btns';

function SuccessComplete({props} : any){
    const navi = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            return navi(props); // 指定画面へリダイレクト
        }, 1000);
    });

    return(
        <main
            className='Success'
            style={{ minHeight: 'calc(100vh - 330px)' }}
        >
            <h1 style={{
                textAlign : 'center',
                margin: '100px 0px',
                height: '50px'
            }}>送信完了いたしました。</h1>

            <p style={{
                margin: '30px 20px'
            }}>自動で画面が切り替わらない場合は以下のボタンを押してください。</p>

            <Btns props={ '' } />
        </main>
    )
}

export default SuccessComplete;