// モジュール
import React from 'react';
import { Link } from 'react-router-dom';

// メソッド
import { format } from '../../method/common/format';
import { setNoPhoto } from '../../method/common/setNoPhoto';

// データ
import { ImageData } from '../../data/common/ImageData';
import { BlogData, BlogLinkData } from '../../data/common/BlogData';
import { OwnerLinkData } from '../../data/common/OwnerData';

// スタイル
import '../../style/blog/blogCassette.css';

function BlogCassette({props} : any){
    const BLOG_ID           = props.blogId;
    const BLOG_USER_ID      = props.userId;
    const BLOG_CREATED_DATE = props.createdDate;
    const BLOG_TITLE        = props.title;
    const IMAGE_DESC        = props.description;
    const IMAGE_CATEGORY    = props.category;
    const LINK_TARGET       = props.ownerFlg ? OwnerLinkData.EDIT.link : BlogLinkData.DETAIL.link;
    const SET_LINK          = format.createLink(LINK_TARGET, BLOG_ID);
    const IMGAE_SRC         = props.imageFlg ? format.setSrc(BLOG_USER_ID, BlogData.BLOGS, BLOG_ID, BlogData.FILENAME) : setNoPhoto();
    const PC_FLG            = window.innerWidth > 1000;
    const CASSETTE_TITLE    = <h3 className='blog_cassette_title'>{ BLOG_TITLE }</h3>
    const CASSETTE_ATTACHED = (
        <div className='blog_cassette_attached'>
            <img src={ ImageData.Date30.src } className='blog_cassette_icon' loading='lazy' alt='calender_icon' />
            <p className='blog_cassette_date'>{ BLOG_CREATED_DATE }</p>
            <img src={ ImageData.Label30.src } className='blog_cassette_icon' loading='lazy' alt='label_icon' />
            <p className='blog_cassette_category'>{ IMAGE_CATEGORY }</p>
        </div>
    );
    const CASSETTE_IMAGE    = <img src={ IMGAE_SRC } className='blog_cassette_image' loading='lazy' alt='post_image' />
    const CASSETTE_TEXT     = <p className='blog_cassette_desc'>{ IMAGE_DESC }</p>

    // カセット構造
    const BLOG_CASSETTE     = PC_FLG ? (
        <section className='blog_cassette_block'>
            <div className='blog_cassette_image_area'>
                { CASSETTE_IMAGE }
            </div>

            <div className='blog_cassette_desc'>
                { CASSETTE_TITLE }

                { CASSETTE_ATTACHED }

                { CASSETTE_TEXT }
            </div>
        </section>
    ) : (
        <section>
            { CASSETTE_TITLE }

            { CASSETTE_ATTACHED }

            { CASSETTE_IMAGE }

            { CASSETTE_TEXT }
        </section>
    );

    return(
        <li className='BlogCassette'>
            <Link to={ SET_LINK } className='blog_cassette_link'>
                { BLOG_CASSETTE }
            </Link>
        </li>
    )
}

export default BlogCassette;