function maxCheck(
        value         : string,
        length        : number,
        setErrMessage : (message : string) => void
    ){
    const res = length < value.length;
    
    const nullMessage = res ? '文字数が超えています。' : '';

    setErrMessage(nullMessage);

    return res;
}

export default maxCheck;