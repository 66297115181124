// モジュール
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';

// データ
import { HelpsConverter, HelpContensConverter } from '../../../data/firebase/owner/Helps';
import { fbApp } from '../../../firebase';

// 取得
export const getHelps = (
    setHelps : (data : any) => void,
    ym : string,
    delFlg = 0,
) => {
    const setYmd = (ym : string, flg : boolean)=>{
        let add = 1;
        let date = '31';
        if(!flg){
            add = -1;
            date = '01';
        }

        const DT = new Date(ym);
        DT.setMonth(DT.getMonth() + add)
        const Y = DT.getFullYear().toString();
        const M = (DT.getMonth()+1).toString().padStart(2, '0');
        return Number(Y + M + date);
    };

    const helpsQuery = query(
        collection(
            fbApp.firestore,
            'helps'
        ),
        where('serh', '>=', setYmd(ym, false)),
        where('serh', '<=', setYmd(ym, true)),
        where('delFlg', '==', delFlg),
        // orderBy('created_at', 'desc')
    ).withConverter(HelpsConverter);

    getDocs(helpsQuery)
    .then(snapShoto => {
        const data = snapShoto.docs.map(doc => ({
            id : doc.id,
            ...doc.data()
        }));

        setHelps(data);
    });
};

export const getHelpContents = (
    setHelpContens : (data : any) => void,
) => {
    const helpsQuery = query(
        collection(
            fbApp.firestore,
            'helpContents'
        ),
        orderBy('val', 'asc')
    ).withConverter(HelpContensConverter);

    getDocs(helpsQuery)
    .then(snapShoto => {
        const data = snapShoto.docs.map(doc => ({
            id : doc.id,
            ...doc.data()
        }));

        setHelpContens(data);
    });
};