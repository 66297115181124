import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isUserAgent } from '../../method/common/isUserAgent';

function AccessPageTop(){
    const location = useLocation();
    const IS_HASH = location.hash !== '' ? true : false;                // #値があるとtrue

    
    useEffect(() => {
        if(IS_HASH){
            const userAgent = window.navigator.userAgent.toLowerCase(); // user-agent取得
            const ua = isUserAgent(userAgent, ['safari']);
            if(!ua){                                                    // safari以外
                window.scrollTo(0, 0);
            }
        }else{
            window.scrollTo(0, 0);
        }
    }, [location, IS_HASH]);

    return null;
}

export default AccessPageTop;