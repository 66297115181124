import Logo50 from '../../images/logo50.PNG';
import Logo630 from '../../images/logo630.PNG';
import NoPhoto320 from '../../images/noPhoto320.png';
import Twitter from '../../images/icon/twitter_logo.png';
import Top1Pc from '../../images/top/top1_pc.webp';
import Top1Sp from '../../images/top/top1_sp.webp';
import Logo30 from '../../images/icon/logo30.PNG';
import Date30 from '../../images/icon/date30.JPG';
import Label30 from '../../images/icon/label30.JPG';
import Contact30 from '../../images/icon/contact30.PNG';
import Blog30 from '../../images/icon/blog30.PNG';
import Profile30 from '../../images/icon/profile30.jpg';

export const ImageData = {
    Logo30 : {
        src  : Logo30,
        alt  : 'site_logo'
    },
    HomeLogo50 : {
        src  : Logo50,
        alt  : 'site_logo'
    },
    HomeLogo930 : {
        src  : Logo630,
        alt  : 'site_logo'
    },
    NoPhoto320  : {
        src  : NoPhoto320,
        alt  : 'no_photo'
    },
    Twitter : {
        src  : Twitter,
        alt  : 'Twitter'
    },
    Top1 : {
        src  : Top1Pc,
        alt  : 'main_imgae'
    },
    Top1Sp : {
        src  : Top1Sp,
        alt  : 'main_imgae'
    },
    Date30 : {
        src  : Date30,
        alt  : 'calender_icon'
    },
    Label30 : {
        src  : Label30,
        alt  : 'label_icon'
    },
    Contact30 : {
        src  : Contact30,
        alt  : 'contact_icon'
    },
    Blog30 : {
        src  : Blog30,
        alt  : 'blog_icon'
    },
    Profile30 : {
        src  : Profile30,
        alt  : 'profile_image'
    },
}