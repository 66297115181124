// モジュール
import { collection, getDocs, query, limit, orderBy, where } from 'firebase/firestore';

// データ
import { CategoryBlogsConverter } from '../../../data/firebase/blog/CategoryBlogs';
import { BlogData } from '../../../data/common/BlogData';

export const getCategoryBlogs = async (
        firestore   : any,
        setBlogs    : any,
        setFlg      : any,
        limitV      : number = 0,
        categoryV   : string = '',
        notBlogId   : string = '',
    ) => {
    const CLEECTION_BLOG = collection(firestore, BlogData.BLOGS);
    const CONVERTER = CategoryBlogsConverter;
    
    const BLOG_QUERY = query(
        CLEECTION_BLOG,
        orderBy('blogId'),
        where('category', '==', categoryV),
        where('blogId', '!=', Number(notBlogId)),
        limit(limitV)
    ).withConverter(CONVERTER);;

    // ブログ取得
    await getDocs(BLOG_QUERY)
        .then((snapShoto) => {
            const data = snapShoto.docs.map((doc) => (
                {
                    ...doc.data(),
                }
            ));

            setBlogs(data);
            setFlg(Object.keys(data).length !== 0);
        })
        .catch((err) => {
            setFlg(false);
        });
}