//データ
import { LinkMaster } from '../LinkMaster';

// ホーム画面 
export const ButtonList = {
    HOME        : LinkMaster.HOME,
    CONTACT     : LinkMaster.CONTACT,
    OWNER       : LinkMaster.OWNER,
    BLOG_INPUT  : LinkMaster.BLOG_INPUT,
    BLOG_LIST   : LinkMaster.BLOG_LIST,
    BLOGS       : LinkMaster.OWNER_BLOGS,
    CONTACTS    : LinkMaster.OWNER_CONTACTS,
};