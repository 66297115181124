// モジュール
import React, { useEffect } from 'react';

function TopScrollHIdden() {
    
    useEffect(() => {
        const target = document.querySelectorAll('.TopScrollHidden');
        const target1 = document.querySelector('.TopScroll');
        const targetArray = Array.prototype.slice.call(target);
    
        const options = {
            root: null,
            rootMargin: '0px 0px 0px 0px',
            threshold: 0
        };
        
        const callback = (entries : any) => {
            entries.forEach((entry : any) => {
                if(entry.isIntersecting) {
                    if(target1 !== null){
                        target1.classList.add('hidden');

                        const html = document.querySelector('html');
                        if(html){
                            html.style.scrollBehavior = 'auto';
                        }
                    }
                }
            });
        };
        
        const observer = new IntersectionObserver(callback, options);
        
        targetArray.forEach(tgt => {
            observer.observe(tgt)
        });
    });

    return(
        <aside
        className='TopScrollHidden'
        style={{
            position : 'absolute',
            top: '0px',
        }}
        ></aside>
    )
}

export default TopScrollHIdden;