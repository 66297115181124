// モジュール
import React, { useEffect, useState } from 'react';
// メソッド
import { formGetValueArr } from '../../method/form/formGetValueArr';
import { formGetValueObj } from '../../method/form/formGetValueObj';

function FormSelects({props} : any){
    const {
        LABEL,
        VALUE,
        OPTIONS,
        setValues,
        VALUE_TYPE,
        VALUES,
        CURRENT      = 0,
        disabredCheck,
    } = props;
    // 未選択値
    const NON_SELECT_VALUE = OPTIONS[0].value;
    // 初期値
    const DEFAULT_VALUE = VALUE !== undefined ? VALUE : OPTIONS[0].value;
    // 入力必須判定
    const REQUIRED_FLG = disabredCheck !== undefined;

    const [nonSelect, setNonSelect] = useState(true);   // 選択の確認用
    const [errMessage, setErrMessage] = useState('');   // エラーメッセージ用
    const [value, setValue] = useState(NON_SELECT_VALUE);  // 選択値

    // リストからoption生成
    const OPTION_ELEMENTS = OPTIONS.map((option : any) => {
        return(
            <option key={ option.value } value={ option.value }>
                { option.text }
            </option>
        )
    });

    // 選択有無を判定
    const selectCheck = (value: string) => {
        const RES = value === NON_SELECT_VALUE;

        if(REQUIRED_FLG){
            setErrMessage(RES ? '選択してください。' : '');
        }

        setNonSelect(RES);

        return RES;
    }
    
    // 値を取得
    const getValue = (value : string) => {
        // 選択値をセット
        setValue(value);
        // 選択有無を判定
        if(selectCheck(value)){
            return;
        }
        
        // 値セット
        if(CURRENT === -1){
            formGetValueObj(VALUES, setValues, VALUE_TYPE, disabredCheck, value, '');
        }else{
            formGetValueArr(VALUES, setValues, VALUE_TYPE, CURRENT, value, '');
        }
    }

    useEffect(() => {
        const RES = VALUE === NON_SELECT_VALUE;
        setNonSelect(RES);
        setValue(RES ? NON_SELECT_VALUE : VALUE);
        // 初期値があれば値セット
        if(NON_SELECT_VALUE !== DEFAULT_VALUE){
            if(CURRENT === -1){
                formGetValueObj(VALUES, setValues, VALUE_TYPE, disabredCheck, DEFAULT_VALUE, '');
            }else{
                formGetValueArr(VALUES, setValues, VALUE_TYPE, CURRENT, DEFAULT_VALUE, '');
            }
        }
    },[
        VALUE, NON_SELECT_VALUE, DEFAULT_VALUE, CURRENT, VALUES, setValues, VALUE_TYPE, disabredCheck
    ]);

    return(
        <section className='form_label select'>
            <label>{ LABEL }</label>
            <select
                onChange={ e => { getValue(e.target.value) } }
                className={ `form_select ${ nonSelect ? 'init' : '' }` }
                required={ REQUIRED_FLG }
                value={ value }
            >
                { OPTION_ELEMENTS }
            </select>
            <p className={ `select_default ${ nonSelect ? '' : 'hidden' }` }>
                { OPTIONS[0].text + (REQUIRED_FLG ? ' （必須）' : '') }
            </p>
            <p className='formError'>{ errMessage }</p>
        </section>
    )
}

export default FormSelects;
