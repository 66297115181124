// モジュール
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

// コンポーネント
import ErrorPages from '../common/ErrorPages';
import Breadcrumbs from '../common/Breadcrumbs';
import TopScrollView from '../common/TopScrollView';
import InCategoryBlogCassette from '../include/InCategoryBlogCassette';
import StructuredDataJson from '../seo/StructuredDataJson';
import Loading from '../common/Loading';

// メソッド
import { metaChange } from '../../method/common/metaChange';
import { getBlog } from '../../method/firebase/blog/getBlog';
import { format } from '../../method/common/format';
import { setNoPhoto } from '../../method/common/setNoPhoto';

// データ
import { BlogData, CategoryList, BlogLinkData } from '../../data/common/BlogData';
import { fbApp } from '../../firebase';
import { BLOG_VALUES } from '../../data/blog/Blog';
import { STATUS_CODE } from '../../data/common/StatusCode';

// スタイル
import '../../style/blog/blogDetail.css';

function BlogDetail(){
    const FIRESTORE       = fbApp.firestore;                                // firestore
    const { blogId }      = useParams();                                    // blogIdパラメータ
    const location        = useLocation();                                  // ロケーション
    const [blog, setBlog] = useState({ status : '', data : BLOG_VALUES });  // ブログ詳細データ
    
    useEffect(() => {
        if(blogId !== undefined){
            getBlog(FIRESTORE, setBlog, blogId);
        };
    }, [
        location,
        FIRESTORE,
        blogId,
    ]);

    let viewTemplate         = <></>;
    let categoryName         = '';
    const BLOG_STATUS        = blog.status;
    const BLOG_STATUS_OK_FLG = BLOG_STATUS === STATUS_CODE.success.ok;
    const BLOG_USER_ID       = blog.data.userId;
    const BLOG_CREATED_DATE  = blog.data.createdDate;
    const BLOG_CREATED_UTC   = blog.data.createdUtc;
    const BLOG_UPDATED_DATE  = blog.data.updatedDate;
    const BLOG_UPDATED_UTC   = blog.data.updatedUtc;
    const BLOG_IMAGE_FLG     = blog.data.imageFlg;
    const BLOG_MAIN_TITLE    = blog.data.title;
    const BLOG_DESCRIPTION   = blog.data.description;
    const BLOG_CATEGORY      = blog.data.category;
    const BLOG_IMAGE1_FLG    = blog.data.image1Flg;
    const BLOG_SUB_TITLE1    = blog.data.title1;
    const BLOG_SUB_TEXT1     = blog.data.text1;
    const BLOG_IMAGE2_FLG    = blog.data.image2Flg;
    const BLOG_SUB_TITLE2    = blog.data.title2;
    const BLOG_SUB_TEXT2     = blog.data.text2;
    const BLOG_IMAGE3_FLG    = blog.data.image3Flg;
    const BLOG_SUB_TITLE3    = blog.data.title3;
    const BLOG_SUB_TEXT3     = blog.data.text3;
    const BLOG_IMAGE4_FLG    = blog.data.image4Flg;
    const BLOG_SUB_TITLE4    = blog.data.title4;
    const BLOG_SUB_TEXT4     = blog.data.text4;
    const BLOG_REF_TITLE1    = blog.data.refeTitle1;
    const BLOG_REF_LINK1     = blog.data.refeLink1;
    const BLOG_REF_TITLE2    = blog.data.refeTitle2;
    const BLOG_REF_LINK2     = blog.data.refeLink2;
    const BLOGLIST_NAME      = BlogLinkData.LIST.name;               // ブログ一覧名
    const BLOGLIST_LINK      = BlogLinkData.LIST.link;               // ブログ一覧のリンク
    const BLOGCATEGORY_LINK  = BLOGLIST_LINK + '/' + BLOG_CATEGORY;  // ブログカテゴリのリンク

    // カテゴリ名セット
    CategoryList.forEach((list) => {
        if(list.value === BLOG_CATEGORY){
            categoryName = 'BLOG ' + list.name;
        };
    });

    // パンくずの値配列を初期化
    const BREADCRUMB_ARRAY = [
        { name : BLOGLIST_NAME, link: BLOGLIST_LINK },
        { name : categoryName , link: BLOGCATEGORY_LINK },
        { name : BLOG_MAIN_TITLE}  ,
    ];

    // コンテンツを配列化
    const CONTENTS_ARRAY = [
        { title : BLOG_SUB_TITLE1, text : BLOG_SUB_TEXT1, flg : BLOG_IMAGE1_FLG },
        { title : BLOG_SUB_TITLE2, text : BLOG_SUB_TEXT2, flg : BLOG_IMAGE2_FLG },
        { title : BLOG_SUB_TITLE3, text : BLOG_SUB_TEXT3, flg : BLOG_IMAGE3_FLG },
        { title : BLOG_SUB_TITLE4, text : BLOG_SUB_TEXT4, flg : BLOG_IMAGE4_FLG },
    ];
    
    // 目次表示
    let outlineCount : number = 0;
    const outlines = BLOG_STATUS_OK_FLG
        ? (
            <nav className='outline'>
                <ol>
                    {
                        CONTENTS_ARRAY.map((content) => {
                            // タイトルがなければ表示なし
                            if(content.title === ''){
                                return <></>;
                            }

                            // 目次番号インクリメント
                            ++outlineCount;
                    
                            // 遷移先idをセット
                            const LINK_ID = `#post_content${ outlineCount }`;
                    
                            return(
                                <li key={ outlineCount }>
                                    <a href={ LINK_ID }>
                                        { content.title }
                                    </a>
                                </li>
                            )
                        })
                    }
                </ol>
            </nav>
    ) : <></>;

    // コンテンツ表示
    let contentCount : number = 0;
    const blogContent = BLOG_STATUS_OK_FLG
    ? (
        <article className='post_contents'>
                {
                    CONTENTS_ARRAY.map((content) => {
                        // タイトル
                        let contentTitle    = content.title;
                        
                        // タイトルがなければ表示なし
                        if(contentTitle === ''){
                            return <></>;
                        }

                        // テキスト
                        let contentText     = content.text;
                        
                        // コンテンツ番号インクリメント
                        ++contentCount;
                        // 画像フラグ
                        const IMAGE_FLG = content.flg;
                        // ファイル名
                        const SUB_IMAGE_FILENAME = `blog_img${ contentCount }.jpg`;
                
                        // 特定の文字列の後に改行を入れる
                        if(contentText !== ''){
                            contentText = contentText.replace(/。|]|）|\s\s/g, '$&\n\n');
                        }
                
                        return(
                            <section key={ contentCount }>
                                <div className='post_content' id={ `post_content${ contentCount }` }>
                                    <h3>{ contentTitle }</h3>
                
                                    {
                                        IMAGE_FLG
                                        ? <img src={ format.setSrc(
                                                    BLOG_USER_ID,
                                                    BlogData.BLOGS,
                                                    blogId,
                                                    SUB_IMAGE_FILENAME
                                                ) } alt='ブログ投稿画像' />
                                        : <></>
                                    }
                                    
                                    <p>{ contentText }</p>
                                </div>
                            </section>
                        )
                    })
                }
            </article>
    ) : <></>;

    // 参考リンクを配列化
    const REFE_ARRAY = [
        { title : BLOG_REF_TITLE1, link : BLOG_REF_LINK1 },
        { title : BLOG_REF_TITLE2, link : BLOG_REF_LINK2 },
    ];

    // 参考リンク表示
    const blogrefe = REFE_ARRAY[0].title !== '' || REFE_ARRAY[1].title !== ''
    ? (
        <section className='reference'>
            <h4>参考リンク</h4>
            {
                REFE_ARRAY.map((refe) => {
                    // タイトルがなければ表示なし
                    if(refe.title === ''){
                        return <></>;
                    }
            
                    return(
                        <li key={ refe.title }>
                            <a href={ refe.link } target='_blank' rel='noopener noreferrer'>{ refe.title }</a>
                        </li>
                    )
                })
            }
        </section>
    ) : <></>;

    if(BLOG_STATUS === ''){
        // 初期
        viewTemplate = <Loading />;
    } else if(
            BLOG_STATUS === STATUS_CODE.error.notAcceptable
            || BLOG_STATUS === STATUS_CODE.error.badRequest
        ){
        // 取得エラー
        viewTemplate = <ErrorPages props={ '' } />;
    } else {
        viewTemplate = (
            <>
                {/* ぱんくず */}
                <Breadcrumbs props={{
                    data     : BREADCRUMB_ARRAY,
                    isHeight : '160px',
                }} />
                
                {/* メインコンテンツ */}
                <main className='BlogDetail'>
                    <div className='date'>
                        {/* 投稿日付 */}
                        <section className='insert_date'>
                            <p>投稿日 : </p>
                            <h6>{ BLOG_CREATED_DATE }</h6>
                        </section>
    
                        {/* 更新日付 */}
                        <section className='updated_date'>
                            <p>更新日 : </p>
                            <h6>{
                                BLOG_CREATED_DATE === BLOG_UPDATED_DATE
                                ? '---'
                                : BLOG_UPDATED_DATE
                            }</h6>
                        </section>
                    </div>
    
                    {/* メインタイトル */}
                    <h1>{ BLOG_MAIN_TITLE }</h1>
    
                    {/* メイン画像 */}
                    <img src={
                            BLOG_IMAGE_FLG
                            ? format.setSrc(
                                    BLOG_USER_ID,
                                    BlogData.BLOGS,
                                    blogId,
                                    BlogData.FILENAME
                                )
                            : setNoPhoto()
                        } alt='ブログ投稿画像' />
    
                    {/* 目次 */}
                    { outlines }
                    
                    {/* 投稿データ */}
                    { blogContent }
    
                    {/* 参考リンク */}
                    { blogrefe }
                </main>
    
                {/* カテゴリ関連ブログ表示 */}
                <InCategoryBlogCassette props={{
                    firestore   : FIRESTORE,
                    category    : BLOG_CATEGORY,
                    blogId      : blogId,
                }} />
    
                {/* ぱんくず */}
                <Breadcrumbs props={{
                    data : BREADCRUMB_ARRAY,
                }} />
    
                {/* トップスクロールボタン */}
                <TopScrollView />
    
                {/* 構造化データ */}
                <StructuredDataJson props={{
                    breadcrumbs : BREADCRUMB_ARRAY,
                    article     : {
                        id          : blogId,
                        headline    : BLOG_MAIN_TITLE,
                        images      : [
                            {flg : BLOG_IMAGE_FLG , filenmae : BlogData.FILENAME},
                            {flg : BLOG_IMAGE1_FLG, filenmae : BlogData.FILENAME1},
                            {flg : BLOG_IMAGE2_FLG, filenmae : BlogData.FILENAME2},
                            {flg : BLOG_IMAGE3_FLG, filenmae : BlogData.FILENAME3},
                            {flg : BLOG_IMAGE4_FLG, filenmae : BlogData.FILENAME4},
                        ],
                        fbColection      : BlogData.BLOGS,
                        datePublished    : BLOG_CREATED_UTC,
                        dateModified     : BLOG_UPDATED_UTC, 
                        mainEntityOfPage : BlogLinkData.DETAIL.link + `/${ blogId }`,
                    },
                }} />
            </>
        );

        // metaタグセット
        metaChange({
            title       : BLOG_MAIN_TITLE,
            description : BLOG_DESCRIPTION,
            url         : window.location.href,
        });
    }

    return(
        <section className='Contents_Container'>
            { viewTemplate }
        </section>
    )
}

export default BlogDetail;