//　データ
import { LinkMaster } from '../LinkMaster';

// フッターリンクリスト
export const FooterLinkData = [
    LinkMaster.CONTACT,
    LinkMaster.PRIVACY_POLICY,
    LinkMaster.DISCLAIMER,
];

// フッターsnsリスト
export const FooterSnsData = [
    LinkMaster.TWITTER
];