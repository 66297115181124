// モジュール
import React, { useEffect, useState, useCallback } from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import allLocales from '@fullcalendar/core/locales-all';
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import { collection, addDoc,  updateDoc, doc } from "firebase/firestore";

// コンテキスト
import { useAuthContext } from '../../context/auth/FireAuthContext';

// コンポーネント
import Btns from '../../common/Btns';

// メソッド
import { getHelps, getHelpContents } from '../../../method/firebase/owner/getHelps';

// データ
import { fbApp } from '../../../firebase';
import { Helps, HelpContens } from '../../../data/firebase/owner/Helps';
import { HelpUid } from '../../../data/common/OwnerData';

// スタイル
import '../../../style/owner/help.css';

function Help(){
    const USDER_ID    = useAuthContext().user.uid;
    const [isModal, setIsModal] = useState(false);
    const [isModalBtn, setIsModalBtn] = useState(false);
    const I_MODE = 1;
    const L_MODE = 2;
    const S_MODE = 3;
    const [mode, setMode] = useState(0);
    const [clickDate, setClickDate] = useState('');
    const [helpValue, setHelpValue] = useState('0');
    const [helps, setHelps] = useState<Helps[]>([]);
    const [thisMonth, setThisMonth] = useState<string>('');
    const [helpList, setHelpList] = useState<[]|{}>([]);
    const [helpId, setHelpId] = useState({helpId : '', uid : ''});
    const [HELPVAL, setHELPVAL] = useState<HelpContens[]>([{val : '', name : '', mo : 0}]);
    const [tripStart, setTripStart] = useState('');
    const [tripEnd, setTripEnd] = useState('');
    const [sumList, setSumList] = useState([{name : '', mo : 0, uid : ''}]);
    let HELP_IN_DATA = [{}];

    // お手伝い者名せっと
    const setHelperName = (uid : String) => {
        let name = '';
        HelpUid.forEach(helper => {
            if(helper.uid === uid){
                name = helper.name;
            }
        })
        return name;
    }

    // お手伝い者名せっと
    const setHelperColor = (uid : String) => {
        let color = '';
        HelpUid.forEach(helper => {
            if(helper.uid === uid){
                color = helper.color;
            }
        })
        return color;
    }

    // 日付生成
    const setYms = (date : Date) => {
        const DT = new Date(date);
        const YEAR = DT.getFullYear().toString();
        const MONTH = (DT.getMonth() + 1).toString().padStart(2, '0');
        const DATE = DT.getDate().toString().padStart(2, '0');
        return `${YEAR}-${MONTH}-${DATE}`;
    }

    // 画面リロード
    const reload = (message : string) => {
        alert(message);
        window.location.reload();
    }

    const setYm = (ym : string, flg : boolean)=>{
        let add = 1;
        if(!flg){
            add = -1;
        }

        const DT = new Date(ym);
        DT.setMonth(DT.getMonth() + add)
        const Y = DT.getFullYear().toString();
        const M = (DT.getMonth()+1).toString().padStart(2, '0');
        return Y + '-' + M;
    };

    // 検索用日付生成
    const getSerh = (yms : string) => {
        const YEAR = yms.substring(0,4);
        const MONTH = yms.substring(5,7);
        const DATE = yms.substring(8,10);
        return Number(YEAR + MONTH + DATE);
    }
    
    useEffect(() => {
        getHelpContents(setHELPVAL);

        const DT = new Date();
        const YEAR = DT.getFullYear().toString();
        const MONTH = (DT.getMonth() + 1).toString().padStart(2, '0');
        const YM = `${YEAR}-${MONTH}`;
        setThisMonth(YM);

        getHelps(setHelps, YM);
        // getWeek(YM);
    }, [
        setHelps, 
        setThisMonth,
        getHelpContents,
    ]);

    const btnClick = (mode : number) => {
        setIsModalBtn(false)
        setMode(mode);
    }

    // 日付クリック
    const handleDateClick = useCallback((arg : DateClickArg) => {
        // 日付生成
        setClickDate(setYms(arg.date));

        // モーダル表示
        setIsModal(true);
        setIsModalBtn(true)
    }, [clickDate,isModal, isModalBtn]);

    // 集計クリック
    const handleSumClick = () => {
        document.body.style.overflow = 'hidden';
        setIsModal(true);
        btnClick(S_MODE);
    };

    // イベントクリック
    const handleEventClick = (info) => {
        const HELP_LISTS = helps.filter(help => {
            return help.date === setYms(info.event.start)
            && help.uid === info.event.groupId
        });

        const HELP_LIST = HELP_LISTS.map((help => {
            let cont = '';
            HELPVAL.forEach(helVal => {
                if(help.val === helVal.val){
                    cont = helVal.name;
                }
            });
            return {name : setHelperName(help.uid), cont : cont, mo : help.mo, id : help.id, uid : help.uid};
        }));

        document.body.style.overflow = 'hidden';
        setHelpList(HELP_LIST);
        setClickDate(HELP_LISTS[0].date);
        setIsModal(true);
        btnClick(L_MODE);
    };

    // モーダル非表示
    const modalClose = () => {
        setIsModal(false);
        setIsModalBtn(false);
        setMode(0);
        setHelpList([]);
        document.body.style.overflow = 'auto';
    }

    // 削除処理
    const handleDelete = (event : any) => {
        event.preventDefault();

        if(USDER_ID !== helpId.uid){
            alert('別のユーザーは削除できません。');
            return;
        }

        if(!window.confirm('削除OK？')){
            return;
        }

        try {
            const HELP_DATA = {
                delFlg : 1,
            }

            // お手伝い削除処理
            const helpsRef = doc(fbApp.firestore, 'helps', helpId.helpId);
            updateDoc(helpsRef, HELP_DATA)
            .then(()=>{
                reload('削除完了');
            })
            .catch((e)=>{
                alert('削除失敗');
                console.log(e);
            });
        } catch(e) {
            alert('削除失敗');
            console.log(e);
        };
    };

    // 送信処理
    const handleSubmit = (event : any) => {
        event.preventDefault();

        if(helpValue === '0'){
            alert('選択してください');
            return;
        }

        const IS_HELP = HELPVAL.filter(arr => {
            return arr.val === helpValue;
        })[0];

        const S_DATE = clickDate;
        const YEAR = S_DATE.substring(0,4);
        const MONTH = S_DATE.substring(5,7);
        const DATE = S_DATE.substring(8,10);

        const HELP_DATA = {
            date   : clickDate,
            serh   : Number(YEAR + MONTH + DATE),
            uid    : USDER_ID,
            val    : IS_HELP.val,
            name   : IS_HELP.name,
            mo     : IS_HELP.mo,
            delFlg : 0,
        }

        try {
            // お手伝い登録処理
            const HELPS_LEF = collection(fbApp.firestore, 'helps');
            addDoc(HELPS_LEF, HELP_DATA)
            .then(() => {
                reload('登録完了');
            })
            .catch((e) => {
                alert('登録失敗');
                console.log(e);
            });
        } catch(e) {
            alert('登録失敗');
            console.log(e);
        };
    };

    // 集計処理
    const handleSum = (event : any) => {
        event.preventDefault();

        const SERH_START = getSerh(tripStart);
        const SERH_END = getSerh(tripEnd);

        if(SERH_START > SERH_END){
            alert('開始日付と終了日付が不正です。');
            return;
        }

        let uid = '';
        let mony = 0;
        let help_sum = [{uid : '', mony : 0}];
        // 日付の範囲内でユーザー毎に集計
        for(let i = 0; i < helps.length; i++){
            if(SERH_START <= helps[i].serh && helps[i].serh <= SERH_END){
                let updFlg = false;
                uid = helps[i].uid;
                mony = helps[i].mo
    
                if(help_sum.length === 1){
                    // 1件目
                    help_sum.push({uid : uid, mony : mony});
                }else{
                    // 2件目以降
                    help_sum.forEach((help, index) => {
                        if(helps[i].uid === help.uid){
                            // 同じ日付・ユーザーの場合に更新
                            help_sum[index].mony += mony;
                            updFlg = true;
                        }
                    })
    
                    if(!updFlg){
                        // 更新でなければ追加
                        help_sum.push({uid : uid, mony : mony});
                    }
                }
            }
        }

        help_sum = help_sum.filter(help => {
            return help.uid !== '';
        })

        const sum_l = help_sum.map(help => {
            return {name : setHelperName(help.uid), mo : help.mony, uid : help.uid};
        });
        setSumList(sum_l);
    };

    if(helps){
        let date = '';
        let uid = '';
        let mony = 0;
        let help_sum = [{date : '', uid : '', mony : 0}];
        // 日付とユーザー毎に集計
        for(let i = 0; i < helps.length; i++){
            let updFlg = false;
            uid = helps[i].uid;
            date = helps[i].date;
            mony = helps[i].mo

            if(help_sum.length === 1){
                // 1件目
                help_sum.push({date : date, uid : uid, mony : mony});
            }else{
                // 2件目以降
                help_sum.forEach((help, index) => {
                    if(helps[i].date === help.date && helps[i].uid === help.uid){
                        // 同じ日付・ユーザーの場合に更新
                        help_sum[index].mony += mony;
                        updFlg = true;
                    }
                })

                if(!updFlg){
                    // 更新でなければ追加
                    help_sum.push({date : date, uid : uid, mony : mony});
                }
            }
        }

        help_sum = help_sum.filter(help => {
            return help.date !== '';
        })

        // カレンダーに表示するデータ整形
        HELP_IN_DATA = help_sum.map(help => {
            return {date : help.date,
                title : `${setHelperName(help.uid)} ${help.mony}`,
                groupId : help.uid,
                backgroundColor: setHelperColor(help.uid),
            };
        });
    }

    const HELP_LIST_VIEW = helpList ? (
        <ul>
            <form onSubmit={ handleDelete } >
                { 
                    helpList.map(help => {
                        return <li key={ help.id } className={``}>
                                    <div>{ help.name }</div>
                                    <div>{ help.cont }</div>
                                    <div>{ help.mo }</div>
                                    <button
                                        type='submit'
                                        className='handel_delete'
                                        onClick={ () => {setHelpId({helpId : help.id, uid : help.uid})} }/>
                                </li>
                    })
                }
            </form>
        </ul>
    ) : <></>;

    const HELP_SUM_VIEW = sumList[0].uid !== '' ? (
        <ul>
            {
                sumList.map(list => {
                    return <li key={ list.uid }>
                                <p>{ list.name }</p>
                                <p>{ list.mo }</p>
                            </li>
                })
            }
        </ul>
    ) : <></>;


    return(
        <section className='Contents_Container'>
            <FullCalendar
                locales={allLocales}
                locale="ja"
                plugins={[
                    dayGridPlugin,
                    interactionPlugin,
                ]}
                initialView="dayGridMonth"
                events={HELP_IN_DATA}
                dateClick={handleDateClick}
                eventClick={handleEventClick}
                headerToolbar={{ left: 'title', center: '', right: 'prev today next' }}
                businessHours={{ daysOfWeek: [1, 2, 3, 4, 5] }}
            />

            <section className={ `help_modal ${ isModal ? '' : 'hidden' }` }>
                <p style={{textAlign:'center'}}>{clickDate}</p>

                <div className={ `help_modal_btn ${isModalBtn ? '' : 'hidden'}` }>
                    <div className='help_modal_btn input'>
                        <p className='input' onClick={() => { btnClick(I_MODE) }}>登録</p>
                    </div>
                </div>

                <div className={ `help_modal_inner input ${ mode !== I_MODE ? 'hidden' : '' }` }>
                    <form onSubmit={ handleSubmit }>
                        <select
                            onChange={ e => { setHelpValue(e.target.value) } }
                        >
                            {HELPVAL.map((option : any) => {
                                return(
                                    <option key={ option.val } value={ option.val }>
                                        { option.name }
                                    </option>
                                )
                            })}
                        </select>

                        <button type='submit'>OK</button>
                    </form>
                </div>

                <div className={ `help_modal_inner list ${ mode !== L_MODE ? 'hidden' : '' }` }>
                    { HELP_LIST_VIEW }
                </div>

                <div className={ `help_modal_inner sum ${ mode !== S_MODE ? 'hidden' : '' }` }>
                    <form onSubmit={ handleSum }>
                        <div className='trip_start'>
                            <label>集計開始日付</label>
                            <input
                                type="date"
                                onChange={ e => { setTripStart(e.target.value) } }
                                className='trip_start_date'
                                required={ true }
                            />
                        </div>
                        <div className='trip_end'>
                            <label>集計終了日付</label>
                            <input
                                type="date"
                                onChange={ e => { setTripEnd(e.target.value) } }
                                className='trip_end_date'
                                required={ true }
                            />
                        </div>

                        <button type='submit'>OK</button>
                    </form>

                    <div className='help_modal_sum_list'>
                        { HELP_SUM_VIEW }
                    </div>
                </div>

                <div className='help_modal_btn close' onClick={() => { modalClose() }}>
                    <p>閉じる</p>
                </div>
            </section>

            <div className='help_sum_btn' onClick={() => { handleSumClick() }}>
                <p>集計</p>
            </div>

            {/* <section
                onClick={() => { modalClose() }}
                className={ `overray ${ isModal ? '' : 'hidden' }` } 
            ></section> */}

            <Btns props={ 'OWNER' } />
        </section>
    )
}

export default Help;