import { ref, uploadBytes, deleteObject } from "firebase/storage";

// 画像アップロード
const imageUpload = (
        storage     : any,
        userId      : string,
        fileName    : string,
        file        : any,
        error       : any,
        ref1        : string = '',
        ref2        : string = ''
    ) => {
        const REF1 = ref1 !== '' ? '/' + ref1 : '';
        const REF2 = ref2 !== '' ? '/' + ref2 : '';
        const STORAGE_REF = ref(storage, `${ userId }${ REF1 }${ REF2 }/${ fileName }`);

        const METADATA = {
            contentType : 'image/jpg',
        };

        uploadBytes(STORAGE_REF, file, METADATA)
        .then(snapshot => {
            console.log(fileName + ' : upload ok');
        })
        .catch(() => {
            console.log(fileName + 'upload ng');
            return error;
        });
};

// 画像削除
const imageDelete = (
        storage     : any,
        userId      : string,
        fileName    : string,
        error       : any,
        ref1        : string = '',
        ref2        : string = ''
    ) => {
        const REF1 = ref1 !== '' ? '/' + ref1 : '';
        const REF2 = ref2 !== '' ? '/' + ref2 : '';
        const STORAGE_REF = ref(storage, `${ userId }${ REF1 }${ REF2 }/${ fileName }`);

        deleteObject(STORAGE_REF)
        .then(() => {
            console.log('image del ok');
        }).catch(() => {
            console.log('image del ng');
            return error;
        });
};

export const IMAGE_UP_DEL = {
    imageUpload,
    imageDelete,
}