import { FirestoreDataConverter } from 'firebase/firestore';

// 型定義
export type Helps = {
    date : string,
    serh : number,
    uid  : string,
    val  : string,
    name : string,
    mo   : number,
    delFlg: number
};

// Firestore用値変換
export const HelpsConverter : FirestoreDataConverter<Helps> = {
    // Firestoreへ送信用
    toFirestore: (data) => {
        return {
            date : data.date,
            serh : data.serh,
            uid  : data.uid,
            val  : data.val,
            name : data.name,
            mo   : data.mo,
            delFlg   : data.delFlg,
        };
    },

    // Firestoreから取得用
    fromFirestore : (snapshot)=> {
        const data = snapshot.data();

        return {
            date : data.date,
            serh : data.serh,
            uid  : data.uid,
            val  : data.val,
            name : data.name,
            mo   : data.mo,
            delFlg   : data.delFlg,
        }
    },
};

// 型定義
export type HelpContens = {
    val : string,
    name  : string,
    mo   : number,
};

// Firestore用値変換
export const HelpContensConverter : FirestoreDataConverter<HelpContens> = {
    // Firestoreへ送信用
    toFirestore: (data) => {
        return {
            val : data.val,
            name : data.name,
            mo   : data.mo,
        };
    },

    // Firestoreから取得用
    fromFirestore : (snapshot)=> {
        const data = snapshot.data();

        return {
            val : data.val,
            name : data.name,
            mo   : data.mo,
        }
    },
};