// モジュール
import React, { useState } from 'react';

// コンポーネント
import Breadcrumbs from '../common/Breadcrumbs';
import TopScrollView from '../common/TopScrollView';
import Btns from '../common/Btns';
import StructuredDataJson from '../seo/StructuredDataJson';

// メソッド
import { metaChange } from '../../method/common/metaChange';
import { format } from '../../method/common/format';

// データ
import { fbApp } from '../../firebase';

// スタイル
import '../../style/info/profile.css';

function Profile(){
    const pageTitle         = 'PROFILE';
    const description       = 'koのプロフィールです。webエンジニアとして活動し、webアプリケーションを開発する会社に勤めながら、個人でホームページやブログの制作を行なっています。';
    const [breadcrumbsData] = useState([{ name : pageTitle, link : '/profile' }]); // パンくずデータ

    // metaタグセット
    metaChange({
        title       : pageTitle,
        description : description,
        url         : window.location.href,
    });

    return(
        <section className='Contents_Container'>
            {/* ぱんくず */}
            <Breadcrumbs props={{
                data : breadcrumbsData,
            }} />

            <main className='Profile'>
                <h1>{ pageTitle }</h1>

                <img src={ format.setSrc(fbApp.userId, 'info', 'profile', 'profile.jpg') } className='profile_image' alt='profile_image' />

                <article>
                    <section className='career'>
                        <h3>略歴</h3>
                        <ul>
                            <li>税理士事務所</li>
                            <li>webエンジニア(現在)</li>
                        </ul>
                    </section>

                    <section className='area'>
                        <h3>拠点</h3>
                        <ul>
                            <li>沖縄県</li>
                        </ul>
                    </section>

                    <section className='comment'>
                        <h3>コメント</h3>
                        <p>初めまして。kackyと申します。</p>
                        <p>webエンジニアとして活動しています。</p>
                        <p>前職の税理士事務所は11年勤めていましたが、まだまだITの成長は止まらないと思いエンジニアとして再スタートしました。</p>
                        <p>現在はwebアプリケーションを開発する会社に勤めながら、個人でホームページやブログの制作を行なっています。</p>
                    </section>
                </article>

                <Btns props={ 'CONTACT' } />
            </main>

            {/* ぱんくず */}
            <Breadcrumbs props={{
                data : breadcrumbsData,
            }} />

            {/* トップスクロールボタン */}
            <TopScrollView />

            {/* 構造化データ */}
            <StructuredDataJson props={{
                breadcrumbs : breadcrumbsData
            }} />
        </section>
    )
}

export default Profile;