// モジュール
import { collection, getDocs, query, orderBy, where } from 'firebase/firestore';

// データ
import { ContactsConverter } from '../../../data/firebase/info/Contacts';
import { fbApp } from '../../../firebase';

// 問い合わせ取得
export const getContacts = (userId : string, setContacts : (data : any) => void) => {
    let contactsQuery = query(
        collection(
            fbApp.firestore,
            'contacts'
        ),
        where('userId', '==', userId),
        orderBy('created_at', 'desc')
    ).withConverter(ContactsConverter);

    getDocs(contactsQuery)
    .then(snapShoto => {
        const data = snapShoto.docs.map(doc => ({
            id : doc.id,
            ...doc.data()
        }));

        setContacts(data);
    });
};