import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getStorage } from "firebase/storage";

// firebase設定値
const firebaseConfig = {
    apiKey              : process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain          : process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId           : process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket       : process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId   : process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId               : process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId       : process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

// firebase初期化
const app = initializeApp(firebaseConfig);

// anarytics初期化
const analytics = getAnalytics(app);

// Firestore初期化
const firestore = getFirestore(app);

// storage初期化
const storage = getStorage(app);

// appCheck初期化
declare global {
    var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined
}

if (process.env.NODE_ENV === 'development') {
    window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true // デバッグ用文字列の生成
}

let reCaptchaSiteKey = '';
if(process.env.REACT_APP_RECAPTCHA_SITE_KEY){
    reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
}

// const appCheck = initializeAppCheck(app, {
//     provider                    : new ReCaptchaV3Provider(reCaptchaSiteKey),
//     isTokenAutoRefreshEnabled   : true
// });

// userId取得
const userId = process.env.REACT_APP_FIREBASE_USER_ID;

// export
export const fbApp = {
    firestore,
    userId,
    analytics,
    // appCheck,
    storage,
}