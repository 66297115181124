// モジュール
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { logEvent } from "firebase/analytics";

// データ
import { HeaderLinkData } from '../../../data/common/HeaderData';
import { fbApp } from '../../../firebase';

function HeaderMenu({props} : any){
    const analytics = fbApp.analytics;

    const [openFlg, setOpenFlg] = useState(false);  // メニュー表示制御フラグ

    const headerMenuList = HeaderLinkData.map((list) => {
        return(
            <li
                key={ list.link }
                className="header_menu_list"
                onClick={ () => {
                    props.open.close();
                    logEvent(analytics, 'search', {
                        content_type    : 'link',
                        content_id      : list.link,
                        items : [{name : list.name}]
                    })
                } }
            >
                <Link to={ list.link }>
                    <img src={ list.icon } alt={ list.alt } loading="lazy" />
                    <p>{ list.name }</p>
                </Link>
            </li>
        )
    });

    useEffect(() => {
        setOpenFlg(props.open.flg);
    }, [props.open.flg]);

    return (
        <section className={ `HeaderMenu ${openFlg ? '' : 'hidden'}` }>
            <nav>
                <ul>
                    { headerMenuList }
                </ul>
            </nav>
        </section>
    )
}

export default HeaderMenu;