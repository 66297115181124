//データ
import { LinkMaster } from '../LinkMaster';

// ヘッダーメニューリンクリスト
export const OwnerLinkData = {
    INPUT : LinkMaster.BLOG_INPUT,
    EDIT  : LinkMaster.BLOG_EDIT,
    BLOGS : LinkMaster.OWNER_BLOGS,
};

// オーナーuid
export const OwnerUid = [
    'dUsRan8gEZRrunWSoN3RVFnikhA2',
];

// お手伝いuid
export const HelpUid = [
    {uid : 'hmK7IezFkXUwiB5XLOTEb0fzCq23', name : 'かず', color : 'red'},
    {uid : 'dUsRan8gEZRrunWSoN3RVFnikhA2', name : 'かず', color : 'red'},
    {uid : 'oQo1ywvdInXGG4YfhGkm8EFAU5r1', name : 'みか', color : 'pink'},
    {uid : '0VPCmI1PMQaFtG7FI6pAuHu9MZp1', name : 'みか', color : 'pink'},
    {uid : '0SMHNNmWnSbrJxElKMJNlbVRxxx2', name : 'とわ', color : '#0066FF'},
    {uid : 'yLEnGUQrFyRY6QG5IldWF0xmsYn2', name : 'かの', color : '#66FFFF'},
    {uid : 'e1JMDTadjhQIpg6cIib6bNnRcjB2', name : 'みう', color : '#FF99CC'},
];

export const HELPVAL = [
    {val: '0', name : '選択', mo : 0},
    {val: '1', name : 'しっこ片付け', mo : 3},
    {val: '2', name : 'うんち片付け', mo : 5},
    {val: '3', name : 'ミウお風呂', mo : 5},
    {val: '4', name : 'お料理', mo : 3},
    {val: '5', name : 'お掃除', mo : 3},
];

