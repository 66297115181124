// モジュール
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// コンテキスト
import { useAuthContext } from '../../context/auth/FireAuthContext';

// コンポーネント
import BlogCassette from '../../blog/BlogCassette';
import TopScrollView from '../../common/TopScrollView';
import ErrorPages from '../../common/ErrorPages';

// メソッド
import { metaChange } from '../../../method/common/metaChange';
import { getBlogs } from '../../../method/firebase/blog/getBlogs';

// データ
import { fbApp } from '../../../firebase';
import { STATUS_CODE } from '../../../data/common/StatusCode';

// スタイル
import '../../../style/blog/blogList.css';

function OwnerBlogs(){
    let pageTitle               = 'BLOGS';                              // ページタイトル
    let description             = '';                                   // description
    let viewTemplate            = <></>;                                // 表示テンプレート
    const location              = useLocation();                        // ロケーション
    const { user }              = useAuthContext();                     // 認証ユーザー取得
    const userId                =  user.uid;                            // 認証ユーザーのuid
    const [blogs, setBlogs]     = useState({ status : '', data : [] }); // ブログデータ
    
    useEffect(() => {
        // ブログ取得
        getBlogs(fbApp.firestore, setBlogs, 0, '', userId);
    }, [
        userId, location
    ]);

    // ブログリスト
    if(blogs.status === STATUS_CODE.success.ok){
        viewTemplate = (
            <>
                <main className='Blogs'>

                    <h1>{ pageTitle }</h1>
                    
                    <section className='list'>
                        <ul className='blog_cassettes'>
                            {
                                blogs.data.map((blog: any) => {
                                    return(
                                        <BlogCassette props={ blog } key={ blog.blogId } />
                                    )
                                })
                            }
                        </ul>
                    </section>

                    {/* トップページボタン */}
                    <TopScrollView />
                </main>
            </>
        )

        // metaタグセット
        metaChange({
            title       : pageTitle,
            description : description,
            noIndexFlg  : true
        });
    }else{
        viewTemplate = <ErrorPages props={ '' } />;
    }

    return(
        <section className='Contents_Container'>
            { viewTemplate }
        </section>
    )
}

export default OwnerBlogs;